import React, { useState, useEffect, useCallback } from 'react';
import { collection, addDoc, updateDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseApp';
import ConvertToCSV from './CSV/csvConvert'
import LottieAnimation from '../Lottie/Lottie';
import { addDays, differenceInDays  } from 'date-fns';
import Location from '../Location/Location';
import UploadVideo from '../Upload/UploadVideo';
import UploadAudio from '../Upload/UploadAudio';
import Industries from '../Industies/Industries';
import DateRangeModal from './DatePicker/DatePicker';
import Logo from '../Images/Streamwave_Blk.png';
import chevron from '../Images/chevron_down.svg';
import finished from '../Images/done.svg';
import warning from '../Images/warning.svg';
import AdVoice from '../AdVoice/AdVoice.js';
import checkmarkAnim from "../Lottie/59945-success-confetti.json";
// import PaymentForm from "../Payment/Payment"
import UploadLogo from '../Upload/UploadLogo';
import help from '../Images/help.svg';
import logo from '../AdVoice/sw_logo.png';
import './create.css';

function Create(props) {
    const [errorMsg, setErrorMsg] = useState(null);
    const [showAdVoice, setShowAdVoice] = useState(false);
    const [proceedToPayment, setProceedToPayment] = useState(false);
    const [activeToggle, setActiveToggle] = useState("video");
    const [referralId, setReferralId] = useState(null);
    const [isSplit, setIsSplit] = useState(false);
    const [expandUpload, setExpandUpload] = useState(true);
    const [expandTargeting, setExpandTargeting] = useState(false);
    const [expandCampaign, setExpandCampaign] = useState(false);
    const [expandPayment, setExpandPayment] = useState(false);
    const [expandContact, setExpandContact] = useState(false);
    const [step1Complete, setStep1Complete] = useState(false);
    const [step2Complete, setStep2Complete] = useState(false);
    const [step3Complete, setStep3Complete] = useState(false);
    const [step4Complete, setStep4Complete] = useState(false);
    const [step5Complete, setStep5Complete] = useState(false);
    const [creative, setCreative] = useState([]);
    const [campagin, setCampaign] = useState({
        campaign_name: null,
        company_website: null,
        campaign_landing_page: null,
        company_name: null,
        business_address:null,
        campagin_flight_dates: new Date()+"-"+new Date().setDate(new Date().getDate() + 30),
        campagin_duration: 30
    });
    const [contact, setContact] = useState({
        first_name: null,
        last_name: null,
        phone: null,
        email: null
    });
    const [targeting, setTargeting] = useState({
        industries: null,
        locals: null,
        audiences: null,
        objective: null,
    });
    const [budget, setBudget] = useState({
        tc_checked: false,
        budget: 2500
    });

    useEffect(() => {
        const url = new URL(window.location);
        const referralIdParam = url.searchParams.get('referral_id');
        const splitParam= url.searchParams.get('split');
        if(referralIdParam){
            setReferralId(referralIdParam)
        }
        if(splitParam){
            setIsSplit(true)
        }
      }, []);

    const toggleUpload = () => {
        setExpandUpload(!expandUpload)
    }
    const toggleTargeting = () => {
        setExpandTargeting(!expandTargeting)
    }
    const togglePayment = () => {
        verifyAdCompletion()
    }

    const verifyAdCompletion = () => {
        if (step1Complete && step2Complete && step3Complete && step4Complete) {
            setExpandPayment(!expandPayment)
            setErrorMsg(null)
        } else {
            if (!step1Complete) {
                setErrorMsg('Opps! Step #1 is incomplete')
                return
            }
            if (!step2Complete) {
                setErrorMsg('Opps! Step #2 is incomplete')
                return
            }
            if (!step3Complete) {
                setErrorMsg('Opps! Step #3 is incomplete')
                return
            }
            if (!step4Complete) {
                setErrorMsg('Opps! Step #4 is incomplete.')
                return
            }
        }
    }


    const toggleCampaign = () => {
        setExpandCampaign(!expandCampaign)
    }
    const toggleContact = () => {
        setExpandContact(!expandContact)
    }
    const completeStepOne = () => {
        setStep1Complete(true)
    }

    const updateCampaign = (field, value) => {
        let curCampaign = { ...campagin }
        curCampaign[field] = value
        setCampaign(curCampaign)

        validateCampaignSection(curCampaign)
    }

    const validateCampaignSection = (campaignObj) => {

        console.log(campaignObj)
        if (Object.values(campaignObj).every(value => value !== null && value !== "")) {
            setStep3Complete(true)
        } else {
            setStep3Complete(false)
        }
    }

    const updateBudget = (field, value) => {
        let curBudget = { ...budget }
        curBudget[field] = value
        setBudget(curBudget)
        validateBudgetSection(curBudget)
    }

    const validateBudgetSection = (budgetObj) => {
        if (budgetObj.budget >= 2500 && budgetObj.tc_checked) {
            setStep5Complete(true)
        } else {
            setStep5Complete(false)
        }
    }

    const updateTargeting = (field, value) => {
        let curTargeting = { ...targeting }
        curTargeting[field] = value
        setTargeting(curTargeting)
        validateTargetingSection(curTargeting)
    }

    const validateTargetingSection = (curTargeting) => {
        let targetingObj = { ...curTargeting }
        if (Object.values(targetingObj).every(value => value !== null && value !== undefined)) {
            setStep2Complete(true)
        } else {
            setStep2Complete(false)
        }
    }

    const updateContact = (e, key) => {
        let contactRef = { ...contact }
        contactRef[key] = e.target.value
        //Look through values
        if (Object.values(contactRef).every(value => value !== null && value !== "")) {
            setStep4Complete(true)
        } else {
            setStep4Complete(false)
        }
        setContact(contactRef)
    }

    const handleLogoUpload = (url) => {
        //add utm tag
        if (url !== "") {
            updateCampaign('company_logo', url)
        } else {
            updateCampaign('company_logo', null)
        }
    }

    
    const updateCreative = (type, url) => {
        setCreative((prevCreative) => [
          ...prevCreative,
          { type: type, url: url }
        ]);
      };


      const removeCreative = (url) => {
        // Create a new array excluding the item with the matching URL
        const newSet = creative.filter(item => item.url !== url);
        
        // Update the state with the new array
        setCreative(newSet);
    };


    const createAdCampagin = () => {
        let adOrder = {}
        adOrder.creative = creative
        adOrder.campaign = campagin
        adOrder.contact = contact
        adOrder.targeting = targeting
        adOrder.budget = budget.budget
        return adOrder
        //Get Payment Transation ID:
        //sendToDB(adOrder)
    }

    function createOpportunity(opportunityData, customFields) {
        // Salesforce API endpoint for creating an Opportunity
        var endpoint = 'streamwave.my.salesforce.com/services/data/v52.0/sobjects/Opportunity/';
        customFields=createAdCampagin()
        // Prepare the request payload
        var payload = {
            "Name": "Test opportunity",
            "Accountid": "XXXXXXXXXXXXX",
            "closedate": "2021-13-12",
            "stagename":"Needs Analysis",
            "Probability": "10"
            }
      
        // Add custom fields to the payload
        for (var field in customFields) {
          if (customFields.hasOwnProperty(field)) {
            payload[field] = customFields[field];
          }
        }
      
        // Perform the API request
        fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer 6Cel800DDn00000DtC2I888Dn0000016MGxoV4SedZPf82i5LuGZQYzvvJ0hCG3P9oE5gperrd8mQeQpXeVBhCz5TiXkJNx2oLJxQgN1jIC' // Replace with your access token
          },
          body: JSON.stringify(payload)
        })
          .then(function(response) {
            if (!response.ok) {
              throw new Error('Failed to create Opportunity. Status: ' + response.status);
            }
            return response.json();
          })
          .then(function(data) {
            console.log('Opportunity created successfully:', data);
          })
          .catch(function(error) {
            console.error('Failed to create Opportunity:', error);
          });
      }
      

      
    const sendToDB = async (adOrder) => {
        setProceedToPayment(true)
        let order = {};
        order.campaign = createAdCampagin();
        order.gross_spend = parseInt(budget.budget);
        order.processing_fees = parseFloat((order.gross_spend * 0.029 + 0.3).toFixed(2));
        order.streamwave_commission = parseFloat((((order.gross_spend-order.processing_fees) * 0.25)+order.processing_fees).toFixed(2));
        order.net_spend = parseFloat((order.gross_spend - order.streamwave_commission).toFixed(2));
        order.submissionDate = new Date();
        order.referral_id = referralId;
        if(referralId){
            if(isSplit){
                order.referral_commission=parseFloat(((order.gross_spend-order.processing_fees)*.05).toFixed(2))
            }else{
                order.referral_commission=parseFloat(((order.gross_spend-order.processing_fees)*.1).toFixed(2))
            }
            
        }else{
            order.referral_commission=0
        }

        order.streamwave_net = order.streamwave_commission-order.referral_commission
        
        order.payment_status="Pending"
        try {
          const docRef = doc(collection(db, 'campaigns'));
          await setDoc(docRef, { ...order, documentId: docRef.id });
          //console.log('Campaign created with ID:', docRef.id);
          //console.log(order); // Access the updated document data directly from the `order` object
            order.documentId=docRef.id
            checkout(order)
          // Handle success or update state if needed
        } catch (error) {
          console.error('Error creating document:', error);
          // Handle error if needed
        }
      };
      

    const checkout = async (order) => {
        if(!step5Complete){
            return
        }
        await fetch('https://us-central1-streamwave-a18b7.cloudfunctions.net/createPaymentSession', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(order),
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                //console.log(response);
                window.location.assign(response.checkoutUrl)
                setProceedToPayment(false)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div id="create-container">
            {activeToggle === "audio" ? 
            <AdVoice assistant_id="asst_x2eFu3RvIsrG10Nn1CfRug7B" name="AdVoice AI" logo={logo}></AdVoice>:null
            }
        
            <div style={{ marginBottom: "40px" }} onClick={() => {if(referralId !==null){window.location.href = "/?referral_id="+referralId}else{window.location.href = "/"}}}>
                <img id="create_logo" src={Logo} alt="Streamwave_Logo" />
            </div>
            {/* A/V Toggle */}
            <div className="audio-video-toggle">
                <div className="toggleAudio" style={{ color: activeToggle === "audio" ? 'white' : 'black', zIndex: 2 }} onClick={() => { setActiveToggle('audio') }}>Audio Ad</div>
                <div className="toggleVideo" style={{ color: activeToggle === "video" ? 'white' : 'black', zIndex: 2 }} onClick={() => { setActiveToggle('video') }}>Video Ad</div>
                <div className="slider-focus" style={{ transform: activeToggle === "video" ? 'translate(102px, 0px)' : 'translate(0px, 0px)' }}></div>
            </div>
            {/* Upload Video */}
            {activeToggle === "video" ?
                <section className="section-wrapper" style={{ maxHeight: expandUpload ? '700px' : '68px' }}>
                    <div className="step-header" onClick={() => { toggleUpload() }}>
                        <div className="progress-step-num" style={{ backgroundColor: step1Complete ? 'var(--primary-color)' : 'transparent', color: step1Complete ? 'white' : 'var(--primary-color)' }}>1</div>
                        <div>
                            <h4>Upload Video</h4>
                        </div>
                        <div className="chevron-container">
                            {step1Complete ?
                                <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation>
                                : null
                            }
                            <img className="chevron-img" src={chevron} alt="chevron" style={{ transform: expandUpload ? 'rotate(0deg)' : 'rotate(90deg)' }}></img>
                        </div>
                    </div>
                    <div className="create-content-wrapper" style={{ display: expandUpload ? 'block' : 'none' }}>
                        <UploadVideo completeStepOne={completeStepOne} creative={creative} updateCreative={updateCreative} removeCreative={removeCreative} type="Video"></UploadVideo>
                    </div>
                </section> : null
            }
            {/* Upload Audio */}
            {activeToggle === "audio" ?
                <section className="section-wrapper" style={{ maxHeight: expandUpload ? '700px' : '68px' }}>
                    <div className="step-header" onClick={() => { toggleUpload() }}>
                        <div className="progress-step-num" style={{ backgroundColor: step1Complete ? 'var(--primary-color)' : 'transparent', color: step1Complete ? 'white' : 'var(--primary-color)' }}>1</div>
                        <div>
                            <h4>Upload Audio</h4>
                        </div>
                        <div className="chevron-container">
                            {step1Complete ?
                                <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation>
                                : null
                            }
                            <img className="chevron-img" src={chevron} alt="chevron" style={{ transform: expandUpload ? 'rotate(0deg)' : 'rotate(90deg)' }}></img>
                        </div>
                    </div>
                    <div className="create-content-wrapper" style={{ display: expandUpload ? 'block' : 'none' }}>
                        <UploadAudio completeStepOne={completeStepOne} type="Audio"></UploadAudio>
                    </div>
                </section> : null
            }
            {/* Targeting Objectives */}
            <section className="section-wrapper" style={{ overflow: 'visible' }}>
                <div className="step-header" onClick={() => { toggleTargeting() }}>
                    <div className="progress-step-num" style={{ backgroundColor: step2Complete ? 'var(--primary-color)' : 'transparent', color: step2Complete ? 'white' : 'var(--primary-color)' }}>2</div>
                    <h4>Targeting Objectives</h4>
                    <div className="chevron-container">
                        {step2Complete ?
                            <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation> : null
                        }
                        <img className="chevron-img" src={chevron} alt="chevron" style={{ transform: expandTargeting ? 'rotate(0deg)' : 'rotate(90deg)' }}></img>
                    </div>
                </div>
                <div style={{ padding: '20px', overflow: 'visible', display: expandTargeting ? 'block' : 'none' }}>
                    <div style={{ position: 'relative', marginBottom: '16px' }}>
                        <label htmlFor="select-industry">Select Industry
                            <span className="tooltip" title="Choose an industry to show relevant audience segments.">?</span>
                        </label>
                        <Industries updateIndustries={updateTargeting} updateAudiences={updateTargeting}></Industries>
                    </div>
                    <div style={{ position: 'relative', marginBottom: '16px' }}>
                        <label htmlFor="company-name">Geo-Targeting
                            <span className="tooltip" title="Add one or more locations where you want your ad to be displayed. ">?</span>
                        </label>
                        <Location updateLocals={updateTargeting}></Location>
                    </div>


                    <div style={{ position: 'relative' }}>
                        <label htmlFor="company-name">Tell us more about your marketing objectives
                            <span className="tooltip" title="This helps us get a better understanding of your unique needs and goals.">?</span>
                        </label>
                        <textarea onChange={(e) => { if (e.target.value !== "") { updateTargeting('objective', e.target.value) } else { updateTargeting('objective', null) } }} placeholder="Example: We are a local plumbing and heating company based out of Columbus, Ohio. Our core customers are households with an income over $80k/year and a credit score over 650. For this campaign, we would like to target customers in Jefferson and Mifflin counties who live in homes built prior to 1987."></textarea>
                    </div>
                </div>
            </section>
            {/* Campaign Details */}
            <section className="section-wrapper">
                <div className="step-header" onClick={() => { toggleCampaign() }}>
                    <div className="progress-step-num" style={{ backgroundColor: step3Complete ? 'var(--primary-color)' : 'transparent', color: step3Complete ? 'white' : 'var(--primary-color)' }}>3</div>
                    <h4>Campaign Details</h4>
                    <div className="chevron-container" >
                        {step3Complete ?
                            <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation> : null
                        }
                        <img className="chevron-img" src={chevron} alt="chevron" style={{ transform: expandContact ? 'rotate(0deg)' : 'rotate(90deg)' }}></img>
                    </div>
                </div>
                    <div style={{padding: '20px', overflow: 'visible', display: expandCampaign ? 'block' : 'none'}}>
                        <DateRangeModal updateDates={updateCampaign} />
                        <div className="content-grid">
                            <div className="input-wrapper">
                                <label htmlFor="campaign-name">Campaign Name
                                    <span className="tooltip" title="Your campaign name will be used for performance reports only">?</span>
                                </label>
                                <input type="text" onChange={(e) => { updateCampaign('campaign_name', e.target.value) }} />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="landing-page-url">Landing Page URL
                                    <span className="tooltip" title="Enter the URL where users will be directed after clicking on your ad.">?</span>
                                </label>
                                <input type="text" onChange={(e) => { updateCampaign('campaign_landing_page', e.target.value) }} />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="company-name">Company Name</label>
                                <input type="text" onChange={(e) => { updateCampaign('company_name', e.target.value) }} />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="company-webiste">Company Website</label>
                                <input type="url" onChange={(e) => { updateCampaign('company_website', e.target.value) }} />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="company-webiste">Business Address</label>
                                <input type="url" onChange={(e) => { updateCampaign('business_address', e.target.value) }} />
                            </div>

                        </div>
                        {/* <div style={{ display: 'block', margin: 'auto', textAlign: 'center' }}>
                            <UploadLogo handleLogoUpload={handleLogoUpload}></UploadLogo>
                        </div> */}

                    </div>
                
            </section>

            <section className="section-wrapper">
                <div className="step-header" onClick={() => { toggleContact() }}>
                    <div className="progress-step-num" style={{ backgroundColor: step4Complete ? 'var(--primary-color)' : 'transparent', color: step4Complete ? 'white' : 'var(--primary-color)' }}>4</div>
                    <h4>Contact Information</h4>
                    <div className="chevron-container" >
                        {step4Complete ?
                            <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation> : null
                        }
                        <img className="chevron-img" src={chevron} alt="chevron" style={{ transform: expandContact ? 'rotate(0deg)' : 'rotate(90deg)' }}></img>
                    </div>
                </div>
                {expandContact ?
                    <div className='create-content-wrapper'>
                        <div className="content-grid">
                            <div className="input-wrapper">
                                <label htmlFor="company-name">First Name</label>
                                <input type="text" required onChange={(e) => { updateContact(e, 'first_name') }} value={contact.first_name} />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="company-name">Last Name</label>
                                <input type="text" required onChange={(e) => { updateContact(e, 'last_name') }} value={contact.last_name} />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="company-name">Phone Number</label>
                                <input type="tel" required onChange={(e) => { updateContact(e, 'phone') }} value={contact.phone} />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="company-name">Email</label>
                                <input type="email" required onChange={(e) => { updateContact(e, 'email') }} value={contact.email} />
                            </div>
                        </div>
                        <p>Designate a main point of contact for this ad campaign. They'll receive monthly performance reports and be our go-to contact for any issues. Let's work together to ensure the best results for your business.</p>
                    </div> : null
                }
            </section>
            <section className="section-wrapper">
                <div className="step-header" onClick={() => { togglePayment() }}>
                    <div className="progress-step-num" style={{ backgroundColor: step5Complete ? 'var(--primary-color)' : 'transparent', color: step5Complete ? 'white' : 'var(--primary-color)' }}>5</div>
                    <h4>Budget</h4>
                    <div className="chevron-container" onClick={() => { togglePayment() }}>
                        {step5Complete ?
                            <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation> : null
                        }

                        <img className="chevron-img" src={chevron} alt="chevron" style={{ transform: expandPayment ? 'rotate(0deg)' : 'rotate(90deg)' }}></img>
                    </div>
                </div>
                {expandPayment ?
                    <div className='create-content-wrapper'>
                        {/* <div className="gtg">Looking good! Finalize your ad buy and you're all set!</div> */}
                        <div style={{ display: 'grid', placeContent: 'center' }}>
                            {campagin.campagin_duration * 80 > 2500 ?
                                <p style={{ textAlign: 'center', fontSize: '14px', width: 'auto', paddingLeft: '5%', paddingRight: '5%' }}>
                                    {"Based on your flight dates ("}<strong>{campagin.campagin_duration} days</strong>{") we recommend a budget of at least "}
                                    <strong>{"$" + campagin.campagin_duration * 80 + " "}</strong>($80/day) {"to optimize your return on ad spend (ROAS)."}
                                </p> : null
                            }
                            <div className="input-wrapper">
                                <div style={{ textAlign: 'center', fontSize: '14px', fontWeight: '600' }} >Set Budget (USD)</div>
                                <input type="number" min="2500" style={{ maxWidth: '300px', color: 'var(--primary-color)', fontSize: '24px', fontWeight: 600, textAlign: 'center', outline: budget.budget < 2500 ? '2px solid red' : '2px solid var(--primary-color)' }} required onChange={(e) => { updateBudget("budget", e.target.value) }} value={budget.budget} />
                            </div>
                            {budget.budget < 2500 ?
                                <p style={{ fontSize: '12px', color:'red', fontWeight:500, textAlign: 'center', marginTop:'4px' }}>*Budget too low: Please enter a budget over $2,500*</p>:<p style={{ fontSize: '12px', fontWeight: 500, textAlign: 'center', marginTop: '4px' }}>*Minimum required budget is $2,500 per campaign.*</p>
                            }

                        <div style={{ display: 'block', margin: 'auto' }}>
                            <div style={{ display: 'flex', alignContent: 'center', }}>
                                <input type="checkbox" checked={budget.tc_checked} onChange={() => { updateBudget('tc_checked', !budget.tc_checked) }} />
                                <p style={{ marginLeft: '8px', fontWeight: 500, fontSize: '14px', opacity: .9 }} >I agree to the <a href="https://sites.google.com/streamwave.io/termsofservice/home">terms of service</a></p>
                            </div>
                        </div>
                        {step5Complete ?
                        <button id="proceed-to-payment" style={{ marginBottom: '16px' }} onClick={sendToDB}>{proceedToPayment ? "One Moment...":"Proceed to Payment"}</button>:<button id="proceed-to-payment" style={{ marginBottom: '16px', opacity:.5 }} disabled>Proceed to Payment</button>
                        }
                        
                    </div>
              
                    </div> : null
}
            </section >
            {/* <button onClick={()=>{sendToDB()}}>Create Oppertunity</button> */}
    {/* <button onClick={()=>{createAdCampagin()}}>Send to BD</button> */ }
{/* <button onClick={checkout}>Show Stripe CO</button> */ }
{/* <PaymentForm></PaymentForm> */ }

{
    errorMsg ?
        <div className="error-banner">
            <div style={{ display: 'flex' }}>
                <img src={warning} style={{ width: '22px', marginRight: '10px' }} alt="warning"></img>
                <div>{errorMsg}</div>
            </div>
        </div> : null
}

        </div >
    );
}

export default Create;