import React, { useState, useCallback } from 'react';
import { storage } from '../firebase/firebaseApp';
import LottieAnimation from '../Lottie/Lottie';
import checkmarkAnim from "../Lottie/59945-success-confetti.json";
import confetti from "../Lottie/98883-confetti.json";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import errorIcon from '../Images/error.svg'
import './uploadBox.css';
const UploadAudio = (props) => {
  const [logo, setLogo] = useState(null);
  const [logoError, setLogoError] = useState(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [expandRequirments, setExpandRequirments] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadState, setUploadState] = useState('waiting');
  const [uploadedAudioURL, setUploadedAudioURL] = useState(null);
  const [audBitrate, setAudBitrate] = useState(null);
  const [audDuration, setAudDuration] = useState(null);
  const [landingPageURL, setLandingPageURL] = useState(null);
  
  


  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file.type === 'audio/mp3') {
      onDrop([file]);
    }
    if (file.type === 'audio/m4a') {
      onDrop([file]);
    }
    if (file.type === 'audio/wav') {
        onDrop([file]);
      }
  };

  const onDrop = async (files) => {
    setError(null)
    const reader = new FileReader();
    reader.onload = function (event) {
      const audio= document.createElement('audio');
      audio.src = event.target.result;

      audio.addEventListener('loadedmetadata', function () {
        const { duration } = audio;
        const fileSizeBytes = files[0].size;
        const fileSizeMB = fileSizeBytes / 1000000
        const mbps = (fileSizeBytes * 8) / (duration * 1000000)
    
        if (Math.round(duration) !== 15 && Math.round(duration) !== 30) {
           
          setError("Invalid Duration: Please upload a 15 or 30 second audio ad")
          return
        }
        if (mbps < .16) {
            alert(mbps)
          setError("Bitrate too low: Audio bitrate must be over 160 Kbps")
          return
        }
        setAudBitrate(mbps.toFixed(2))

        setAudDuration(Math.round(duration))

        uploadFile(files[0])
      });
    };

    reader.readAsDataURL(files[0]);

  };

  function uploadLogo(file) {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.toLocaleString('default', { month: 'long' });
    const filePath = `logos/${year}/${month}/${file.name}`;
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {

        // Observe state change events such as progress, pause, and resume
        console.log('Upload is ' + (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + '% done');
        if ((snapshot.bytesTransferred / snapshot.totalBytes) * 100 > 0) {
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error(error);
      },
      () => {
        // Handle successful uploads on complete
        console.log('Upload successful');

        props.completeStepOne()
        // You can get the download URL of the uploaded file
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          setLogo(downloadURL)
        });

      }
    );
  }


  function uploadFile(file) {
    setUploadState('prepping')
    const now = new Date();
    const year = now.getFullYear();
    const month = now.toLocaleString('default', { month: 'long' });
    const filePath = `audio/${year}/${month}/${file.name}`;
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {

        // Observe state change events such as progress, pause, and resume
        console.log('Upload is ' + (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + '% done');
        if ((snapshot.bytesTransferred / snapshot.totalBytes) * 100 > 0) {
          setUploadState('uploading')
        }
        setUploadProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error(error);
      },
      () => {
        // Handle successful uploads on complete
        console.log('Upload successful');
        setUploadState('done')
        // props.completeStepOne()
        // You can get the download URL of the uploaded file
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          setUploadedAudioURL(downloadURL)
          props.updateCreative("audio",downloadURL)
        });
      }
    );
  }

  const handleLogoUpload = (event) => {
    const selectedFile = event.target.files[0];
    const fileSize = selectedFile.size / 1024 / 1024; // Convert to MB
    if (fileSize > 2) {
      setLogoError('Please upload an image that is under 2MB');
      setLogo(null);
    } else {
      uploadLogo(selectedFile)
      setLogoError(null);
    }
  };


  const handleDragOver = (e) => {
    e.preventDefault();
    console.log(e.dataTransfer.items[0])
    if (e.dataTransfer.items[0].kind === "file" && e.dataTransfer.items[0].type === "video.mp4") {
      e.dataTransfer.dropEffect = "copy";
    }
    // const allFilesAreJson = Array.from(e.dataTransfer.items).every(
    //   (item) => item.kind === "file" && item.type === "application/json"
    // );
    // if (allFilesAreJson) {
    //   e.dataTransfer.dropEffect = "copy";

    // } else {
    //   e.dataTransfer.dropEffect = "none";

    // }
  };


 
  

  return (
    <div style={{ display: 'block' }}>
      {uploadState === 'done' ?
        <div>
          <div style={{ position: 'absolute', bottom: '0px', pointerEvents: 'none' }}>
            <LottieAnimation animationData={confetti} height={'auto'} width={'100%'}></LottieAnimation>
          </div>
          <audio src={uploadedAudioURL} controls style={{ width: '100%', borderRadius: '8px',}}></audio>
          {/* <input type="url" placeholder='Landing Page URL' required style={{width:'92%', marginTop:'8px'}} onChange={(e)=>{setLandingPageURL(e.target.value)}}/> */}
          <div className="logo-upload-wrapper" style={{marginTop:'16px'}}>
            <div style={{display:logo ? 'none':'block'}}>
            <label htmlFor="logo-upload">Upload Companion Banner</label>
            <input type="file" id="logo-upload" accept="image/*" onChange={handleLogoUpload} />
            </div>
            <p>File Types: JPEG, PNG</p>
            <p>Resolution: 300×250</p>
            {logoError && <div className="error">{logoError}</div>}
            {logo && 
            <div>
            <p>Logo:</p>
            <img src={logo} style={{width:'100px'}} alt="logo"></img>
            </div>
            }
          </div>
        </div> : null
      }
      {uploadState === 'prepping' ?
        <div>
          <strong style={{ width: '100%', textAlign: 'center', textDecoration: 'none' }}>{"Analyzing Upload - One Moment Please... "}</strong>
        </div> : null
      }
      {uploadState === 'uploading' ?
        <div>
          <div style={{ position: 'relative', overflow: 'hidden', height: '4px', backgroundColor: 'var(--primary-color)', width: uploadProgress + '%', borderRadius: '100px' }}>
            <div id="progressSheen"></div>
          </div>
          <p>{"Uploading... " + Math.floor(uploadProgress) + "%"}</p>
        </div> : null
      }
      {uploadState === "waiting" ?
        <div
          className="main-upload"
          style={{}}
          onDragOver={handleDragOver}
          onDragLeave={() => { }}
          onDrop={handleDrop}
        >
          <label id="uploadLabel" htmlFor="fileInput">Upload Audio</label>
          <input
            id="fileInput"
            type="file"
            accept=".mp3, .m4a, .wav"
            onChange={(e) => onDrop(e.target.files)}
          />
        </div> : null
      }
      {error ?
        <div style={{ display: 'flex', alignContent: 'center', gap: '8px', marginTop: '10px' }}>
          <img src={errorIcon} style={{ width: '24px' }} alt="error"></img>
          <div className="error-msg">{error}</div>
        </div> : null
      }
      {uploadState === "uploading" ?
        <div className="upload-parse-container">
          <div className="upload-parse-success">
            <div className="lottie-parse-check">
              <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation>
            </div>
            <div>Duration</div>
            <div className="fileSpec-attr">{audDuration + "s"}</div>
          </div>
          <div className="upload-parse-success">
            <div className="lottie-parse-check">
              <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation>
            </div>
            <div style={{ textAlign: 'center' }}>Bitrate</div>
            <div className="fileSpec-attr">{audBitrate+ " Kbps"}</div>
          </div>
        </div> : null
      }
      {uploadState === "waiting" ?
        <div>
          {!expandRequirments ?
            <div style={{ paddingTop: '20px' }}>
              <strong onClick={() => { setExpandRequirments(true) }} style={{ textDecoration: 'underline' }}>Show Audio Specs</strong>
            </div> :
            <div style={{ paddingTop: '20px' }}>
              <strong onClick={() => { setExpandRequirments(false) }} style={{ textDecoration: 'underline' }}>Hide Audio Specs: </strong>
              <p>File Types: MP3, M4A, WAV</p>
              <p>Duration: Exactly 15 seconds or Exactly 30 Seconds (30 Seconds is Highly Recommended)</p>
              <p>Minimum Bitrate: 160 Kbps</p>
            </div>
          }
        </div> : null
      }


    </div>
  );
};

export default UploadAudio;
