import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import { addDays, differenceInDays  } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './DateRangeModal.css';
import dbl_chev from '../../Images/dlb_chevron.svg';

const defaultStartDate = new Date();
const defaultEndDate = new Date();
defaultEndDate.setDate(defaultStartDate.getDate() + 0);

function DateRangeModal(props) { 
  
  const [dateRange, setDateRange] = useState({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    key: 'selection',
  });
  
  const [showPicker, setShowPicker] = useState(false);

  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
    props.updateDates('campagin_flight_dates', formatDate(ranges.selection.startDate)+"-"+formatDate(ranges.selection.endDate));
    
  };

  useEffect(() => {
    const duration = differenceInDays(dateRange.endDate, dateRange.startDate);
    props.updateDates('campagin_duration', duration);
  }, [dateRange]);

  useEffect(() => {
    const duration = differenceInDays(defaultEndDate, defaultStartDate);
    props.updateDates('campagin_flight_dates', formatDate(defaultStartDate)+"-"+formatDate(defaultEndDate));
    
  }, []);

  // useEffect(() => {
  //   console.log('triggered');
  //   props.updateDates('campagin_start_date', formatDate(defaultStartDate));
  //   props.updateDates('campagin_end_date', formatDate(defaultEndDate));
  //   const duration = differenceInDays(defaultEndDate, defaultStartDate);
  //   props.updateDates('campagin_duration', duration);
  // }, []);


  

  const handleDone = () => {
    console.log(dateRange);
    // Do something with selected date range
    setShowPicker(false);
  };

  const formatDate = (date) => {
    const options = { month: '2-digit', day: '2-digit', year: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  };

  const today = new Date();
  const maxDate = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());

  return (
    <div>
      <div style={{ marginBottom: '4px', marginTop: '10px' }}>
        <div style={{ textAlign: 'center' }}>
          <label htmlFor="flight-dates">Set Flight Dates</label>
        </div>
        <div
          style={{ textAlign: 'center', cursor: 'pointer', fontSize: '20px' }}
          onClick={() => {
            setShowPicker(!showPicker);
          }}
        >
          <strong>
            {formatDate(dateRange.startDate)} - {formatDate(dateRange.endDate)}
          </strong>
        </div>
        {/* <img style={{display:'block', margin:'auto', width:'30px'}} src={dbl_chev} alt="dbl_chev"></img> */}
      </div>
      <div
        className="modal-wrapper"
        style={{ display: showPicker ? 'block' : 'none', height: '500px', overflowY: 'scroll' }}
      >
        <div style={{ width: '100%', display: 'grid', placeContent: 'center' }}>
          <DateRangePicker
            showSelectionPreview={true}
            ranges={[dateRange]}
            className="drp-class"
            rangeColors={['#5c46b3']}
            showDateDisplay={false}
            direction="vertical"
            months={6}
            preventSnapRefocus={true}
            fixedHeight={false}
            onChange={handleSelect}
            minDate={today}
            maxDate={maxDate}
          />
        </div>
      </div>
    </div>
  );
}

export default DateRangeModal;
