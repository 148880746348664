import React, { useState } from 'react';
import './faq.css';

const FAQ = ({ faqData }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="faq-container" >
        <div style={{padding: '40px'}}>
      {faqData.map((faq, index) => (
        <div className="faq" key={index}>
          <div className="faq-question" onClick={() => handleClick(index)}>
            <h3>{faq.question}</h3>
            <div className={`faq-icon ${index === activeIndex ? 'active' : ''}`}>+</div>
          </div>
          {index === activeIndex && (
            <div className="faq-answer" style={{ whiteSpace: 'pre-wrap' }}>
              <p>{faq.answer}</p>
            </div>
          )}
        </div>
      ))}
      </div>
    </div>
  );
};

export default FAQ;
