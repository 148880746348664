import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Audiences from './Audiences';
import baseOptions from './gsheets_base';
import aetOptions from './gsheets_aet';
function IndustrySelect(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState(null);

  const updateSelectedAudiences = (audiences) => {
    if (audiences.length === 0) {
      props.updateAudiences('audiences', null);
    } else {
      props.updateAudiences('audiences', audiences);
    }
  };

  useEffect(() => {
    if (props.partner === 'AET') {
      setOptions(aetOptions);
    } else {
      setOptions(baseOptions); // Reset options if needed
    }
  }, [props.partner]);

  const groupedOptions = options
    ? options.map((option) => {
        if (option.subindustries) {
          return {
            label: option.label,
            options: option.subindustries.map((subindustry) => ({
              label: subindustry.label,
              value: subindustry.value,
            })),
          };
        } else {
          return { label: option.label, value: option.value };
        }
      })
    : [];

  const filterOption = ({ label, value }, string) => {
    label = label.toLocaleLowerCase();
    string = string.toLocaleLowerCase();
    // default search
    if (label.includes(string) || value.includes(string)) return true;

    // check if a group has the filter string as label
    const groupOptions = groupedOptions.filter((group) =>
      group.label.toLocaleLowerCase().includes(string)
    );

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        // Check if the current option is in the group
        const option = groupOption.options.find((opt) => opt.value === value);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
    props.updateIndustries('industries', selected);
  };

  return (
    <div>
      {options && (
        <Select
          value={selectedOptions}
          onChange={handleSelectChange}
          options={groupedOptions}
          isMulti
          filterOption={filterOption}
          placeholder="Select your industry"
          styles={customStyles}
        />
      )}
      {selectedOptions && selectedOptions.length > 0 ? (
        <div>
          {selectedOptions.map((option) => (
            <Audiences
              industry={option}
              updateSelectedAudiences={updateSelectedAudiences}
              key={option.value}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}





const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '2px',
    borderColor: state.isFocused ? 'var(--primary-color)' : provided.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px var(--primary-color);' : provided.boxShadow,
    '&:hover': {
      borderColor: state.isFocused ? 'var(--primary-color)' : provided.borderColor,
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'var(--primary-color)',
    borderRadius: '10px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#FFFFFF',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    '&:hover': {
      backgroundColor: 'var(--primary-color)',
      color: 'white',
      borderRadius: '10px'
    },
  }),
};


export default IndustrySelect;
