import React, { useState } from 'react';
import './checkoutBanner.css';

function CheckoutBanner({ campaigns, totalBudget }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="checkout-banner" style={{ transform: isExpanded ? 'translateY(0)' : 'translateY(100%)' }}>
            <div className="checkout-summary" onClick={toggleExpand}>
                <h3>Total: ${totalBudget}</h3>
                <p>{isExpanded ? 'Hide Details' : 'View Details'}</p>
            </div>

            <div className={`checkout-expanded ${isExpanded ? 'active' : ''}`}>
                {campaigns.map((campaign) => (
                    <div className="line-item" key={campaign.id}>
                        <h4>Campaign {campaign.id}</h4>
                        <p>${campaign.budget.toFixed(2)}</p>
                    </div>
                ))}

                <div className="line-item">
                    <h4>Subtotal</h4>
                    <p>${totalBudget.toFixed(2)}</p>
                </div>

                <div className="checkout-button-container">
                    <button
                        className="checkout-button"
                        disabled={totalBudget < 3500}
                        onClick={() => {
                            if (totalBudget >= 3500) {
                                console.log('Proceed to checkout');
                            }
                        }}
                    >
                        Confirm Order
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CheckoutBanner;
