import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';

const LottieAnimation = ({ animationData, height, width }) => {
  const container = useRef(null);

  useEffect(() => {
    let anim = null;
    if (container.current) {
      anim = lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animationData,
      });
    }
    return () => {
      if (anim) {
        anim.destroy();
      }
    };
  }, [animationData]);

  return <div ref={container} style={{ height: height, width: width, marginRight:'-16px' }} />;
};

export default LottieAnimation;
