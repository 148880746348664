let options = [
  {
    label: 'Retail Automotive',
    value: 'acura',
    subindustries: [
      { label: 'Acura', value: 'https://docs.google.com/spreadsheets/d/1U0PXhgf1cPU86W0FF3XWiwxrlk9vHi6t9AZvE-UKcdQ/edit#gid=0' },
      { label: 'Alfa Romeo', value: 'https://docs.google.com/spreadsheets/d/1G5dNUqnFRc-F39apzS8lgEhUvS-bH07U1Fff3gmHRjA/edit#gid=0'},
      { label: 'Aston Martin', value: 'https://docs.google.com/spreadsheets/d/1KL1WAJm8uGJlE6h0_IwlTOYcNsxfuORjc0w4Tae3rKg/edit#gid=0'},
      { label: 'Audi', value: 'https://docs.google.com/spreadsheets/d/1tHieHvaXwAjQ8SaSmTL6RhxCcm1txiJJtADUx3d75FU/edit#gid=0'},
      { label: 'Bentley', value: 'https://docs.google.com/spreadsheets/d/11uMLcOYZ7JJYduRhoBeeQGLjrrrFtqFst4OW3iiH2ms/edit#gid=0'},
      { label: 'BMW', value: 'https://docs.google.com/spreadsheets/d/13RYJesH4_s8M_9QO3ukJIYZgj_WfHQ0d1czqHAh7X6g/edit#gid=0'},
      { label: 'CDJR Dealer : Chrysler, Dodge, Jeep, RAM', value: 'https://docs.google.com/spreadsheets/d/1cXhPWGHBg4EWDXMyEVzDfuGtHKw_v-sY15f0-FXAMHI/edit#gid=0'},
      { label: 'Ferrari', value: 'https://docs.google.com/spreadsheets/d/1ZhshjunuTNl8PP18JS7iMV5MgMHdne0Peu2LFh68T0k/edit#gid=0'},
      { label: 'Fiat', value: 'https://docs.google.com/spreadsheets/d/1qau1VzgVWB4E0HZN_c-DnFbEoNB_GqmXqUtsSkz_wS4/edit#gid=0'},
      { label: 'Fisker', value: 'https://docs.google.com/spreadsheets/d/12a0L5FJc6_R3XvU3K832DW-ofSLL4FAMt0T52fIHgb8/edit#gid=0'},
      { label: 'Ford', value: 'https://docs.google.com/spreadsheets/d/1wt2KW8vXo1-PS-TSggakpCX1xDTSq5cAUkII8wb0Lus/edit#gid=0'},
      { label: 'Genesis', value: 'https://docs.google.com/spreadsheets/d/1w2vmQAiPOQXmQa7DUkInWgh3BDjLVAIOnwAKjZo-3kU/edit#gid=0'},
      { label: 'GM Dealer : Chevrolet, Buick, GMC, Cadillac', value: 'https://docs.google.com/spreadsheets/d/1WOoAvcDK6RqLRxnTbJ-_roDTxD2LSOW98cI80jYalPI/edit#gid=0'},
      { label: 'Harley-Davidson', value: 'https://docs.google.com/spreadsheets/d/1JUHghACZSMp7NqshxR0wHWcWE0pEAEyIZ-tgn2cl3OE/edit#gid=0'},
      { label: 'Honda', value: 'https://docs.google.com/spreadsheets/d/1yxHcn6MzLtN0thU-NNIm8tRwNalGWkdSIDSmQDmQ7CU/edit#gid=0'},
      { label: 'Hummer', value: 'https://docs.google.com/spreadsheets/d/1-dXxrBg4W7YkP88oKaHJrbA5516BMZGiE_bpKotF48Y/edit#gid=0'},
      { label: 'Hyundai', value: 'https://docs.google.com/spreadsheets/d/1dOKChndGYJhwUTosE4MK02HyTepw1PGEye1EWFB006I/edit#gid=0'},
      { label: 'INFINITI', value: 'https://docs.google.com/spreadsheets/d/1yUkc9G8UbG-SNDpQrCvQ0gTLVIFi2nNjbgLWhuqWAaM/edit#gid=0'},
      { label: 'Jaguar', value: 'https://docs.google.com/spreadsheets/d/1MGVHfb_q4GTmYaSu-k7zUX4-4j5JYp1UcbmdIzmUfag/edit#gid=0'},
      { label: 'Karma', value: 'https://docs.google.com/spreadsheets/d/1ASVblKkk2x-2vGOEwHrk6ZpTdYC0YGlljuQD0lpb41U/edit#gid=0'},
      { label: 'Kia', value: 'https://docs.google.com/spreadsheets/d/1Zrlt-1fwFaCf1u8xaiUEpYAWR8p5UF0CqMky9RoqtC8/edit#gid=0'},
      { label: 'Lamborghini', value: 'https://docs.google.com/spreadsheets/d/1u3CdR4XeUnw10LSurTBvqOHCVEL-OvnhjnRy4WyDNOU/edit#gid=0'},
      { label: 'Land Rover', value: 'https://docs.google.com/spreadsheets/d/1wj636oz2IoFrkf06K8qLfeSkcYaFKZd1Hry2xAuguAk/edit#gid=0'},
      { label: 'Lexus', value: 'https://docs.google.com/spreadsheets/d/176VMHpgl5x2EytFPXeoZlVUejO6Rdxl3xn4iozqO_MI/edit#gid=0'},
      { label: 'Lincoln', value: 'https://docs.google.com/spreadsheets/d/1uj4IHZb7jadsfgSDVLxYnIqoudKmXifp_3jDh5eA5rs/edit#gid=0'},
      { label: 'Lotus', value: 'https://docs.google.com/spreadsheets/d/1QNS3QXMs9ShfZm5M_cjLWsEGVaTYPM2R2E2xGcxPagA/edit#gid=0'},
      { label: 'Lucid Motors', value: 'https://docs.google.com/spreadsheets/d/1lDgE-FFoZ1dMYOyaMT24myWvAp6Q3ntwEMfttZ1bIrw/edit#gid=0'},
      { label: 'Maserati', value: 'https://docs.google.com/spreadsheets/d/1C3n9L4aHbAxhDyl9DJeBjtjV9thnTqU6vJ4cMRnkYG4/edit#gid=0'},
      { label: 'Maybach', value: 'https://docs.google.com/spreadsheets/d/1c1eNaKZCZuQtElRMgMZLHaUOI-BXw6ZEAkFG6ZAGF30/edit#gid=0'},
      { label: 'Mazda', value: 'https://docs.google.com/spreadsheets/d/1ha37coPtA-K3vC7MBUSpO9ehYDl5Q8a2JEd-PF1Wy3k/edit#gid=0'},
      { label: 'McLaren', value: 'https://docs.google.com/spreadsheets/d/1YKO4JYI8UNOSBZ2dKx21dPLuXVUUREkBVskdYmcFuoY/edit#gid=0'},
      { label: 'Mercedes-Benz', value: 'https://docs.google.com/spreadsheets/d/1PflEQ-s-lXbcNs3TZ3gV5cZ9CPrUZDO7pdmM1Edein0/edit#gid=0'},
      { label: 'MINI', value: 'https://docs.google.com/spreadsheets/d/1osMBSTtezq7C4IZgyZpBmiz8YyUEbhVZltkDOyV4X8c/edit#gid=0'},
      { label: 'Mitsubishi', value: 'https://docs.google.com/spreadsheets/d/1XtEYtca23mPMt6dA8aAp2zg7YoVFODYex5G-KkLx_NI/edit#gid=0'},
      { label: 'Nissan', value: 'https://docs.google.com/spreadsheets/d/1BE7OCV4ga6CCD2mvahMhSGTV_U2Ni6PP9vb6q8g-UMw/edit#gid=0'},
      { label: 'Other', value: 'https://docs.google.com/spreadsheets/d/14RAbGWQoaQXJ-RApDMp_NbFRS3VZYiYwuNXkKDnW4Ms/edit#gid=0'},
      { label: 'Polestar', value: 'https://docs.google.com/spreadsheets/d/11xj2jGfNpKodCmT7rtnhTnUyvRhOYcIYJdVbox_eswM/edit#gid=0'},
      { label: 'Porsche', value: 'https://docs.google.com/spreadsheets/d/1SrpW5rRcz0e3WqGV_CIxVTcA3GYuEKha5j6QEixOdy8/edit#gid=0'},
      { label: 'Rivian', value: 'https://docs.google.com/spreadsheets/d/1KSqvWxIYAJpZqNNDlZ4_SsYtfNctzccK3jWQ7bOyzp0/edit#gid=0'},
      { label: 'Rolls-Royce', value: 'https://docs.google.com/spreadsheets/d/1tZb1SRx-641hyHEyN4J5tXPGnik8YN5mDNBElvSVFuM/edit#gid=0'},
      { label: 'Subaru', value: 'https://docs.google.com/spreadsheets/d/1VDXhxNvU7tYwV76z6gRY0ZrcZuO1Bai_NR_mNzb67mU/edit#gid=0'},
      { label: 'Tesla', value: 'https://docs.google.com/spreadsheets/d/1kPul0tpSq5UIjkd7tkg_l6NUvzYJ1dbPflO8P9kMJJM/edit#gid=0'},
      { label: 'Toyota', value: 'https://docs.google.com/spreadsheets/d/1iU2lw_VH7abEK8FdSVCWnKWXKATnuU9npPNQQ6XMlV8/edit#gid=0'},
      { label: 'VinFast', value: 'https://docs.google.com/spreadsheets/d/19AYJsanfxFlO36hakbw203mhonC23BObJ5Vg9OSM3RA/edit#gid=0'},
      { label: 'Volkswagen', value: 'https://docs.google.com/spreadsheets/d/17uD7yVFCy7miWZXB_03J4O5F2rDlb2OQkxgOvryX8o8/edit#gid=0'},
      { label: 'Volvo', value: 'https://docs.google.com/spreadsheets/d/1KEK4KRU92mXOvEwGzzQ-6TBjcP_-XnY3AxK-l5dbPSw/edit#gid=0'},
    ]
  },
  ]
export default options