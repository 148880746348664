import React, { useState, useRef } from 'react';
import { storage } from '../firebase/firebaseApp';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './uploadBox.css';

const OTTRetargetingUpload = (props) => {
    const [ottEnabled, setOttEnabled] = useState(false);
    const [images, setImages] = useState({
        '300x250': null,
        '300x600': null,
        '320x50': null,
        '728x90': null,
    });
    const [errors, setErrors] = useState({});
    const [progress, setProgress] = useState({});
    const fileInputRefs = {
        '300x250': useRef(null),
        '300x600': useRef(null),
        '320x50': useRef(null),
        '728x90': useRef(null),
    };

    const validDimensions = {
        '300x250': [300, 250],
        '300x600': [300, 600],
        '320x50': [320, 50],
        '728x90': [728, 90],
    };

    const handleOttToggle = () => {
        props.ottEnabled(!ottEnabled)
        setOttEnabled(!ottEnabled);
    };

    const handleImageUpload = (file, size) => {
        if (file) {
            validateAndUploadImage(file, size);
        }
    };

    const validateAndUploadImage = (file, size) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;

            img.onload = () => {
                const [expectedWidth, expectedHeight] = validDimensions[size];
                const actualWidth = img.width;
                const actualHeight = img.height;

                // Check if the actual dimensions match the expected dimensions
                if (actualWidth !== expectedWidth || actualHeight !== expectedHeight) {
                    setErrors(prev => ({ ...prev, [size]: `Invalid resolution: Image must be ${expectedWidth}x${expectedHeight}.` }));
                    return;
                }

                const fileSizeMB = file.size / 1024 / 1024; // Convert to MB
                if (fileSizeMB > 2) {
                    setErrors(prev => ({ ...prev, [size]: 'Please upload an image that is under 2MB.' }));
                    return;
                }

                // Clear the error if everything is fine
                setErrors(prev => ({ ...prev, [size]: null }));
                uploadImage(file, size);
            };

            img.onerror = () => {
                setErrors(prev => ({ ...prev, [size]: 'Error loading the image. Please try again.' }));
            };
        };

        reader.readAsDataURL(file);
    };

    const uploadImage = (file, size) => {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.toLocaleString('default', { month: 'long' });
        const filePath = `campaigns/${year}/${month}/${file.name}`;
        const storageRef = ref(storage, filePath);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progressPercentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(prev => ({ ...prev, [size]: progressPercentage }));
            },
            (error) => {
                setErrors(prev => ({ ...prev, [size]: error.message }));
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setImages(prev => ({ ...prev, [size]: downloadURL }));
                    setProgress(prev => ({ ...prev, [size]: null })); // Clear progress after upload
                    props.updateSupportingCreative(downloadURL);
                });
            }
        );
    };

    return (
        <div style={{ display: 'block', position: 'relative', padding: '0px' }}>
            <div style={{ display: 'block', alignItems: 'center', placeContent: 'center', marginBottom: '16px', justifyContent: 'flex-start' }}>
                <input type="checkbox" id="ottRetargeting" checked={ottEnabled} onChange={handleOttToggle} style={{ height: '12px', width: '12px' }} />
                <label htmlFor="ottRetargeting" style={{ marginLeft: '8px', fontFamily: 'DM Sans', fontWeight: 600, fontSize: '15px', textAlign: 'left' }}>
                    Enable OTT Retargeting
                </label>
            </div>

            {ottEnabled && (
                <div>
                    <p style={{ fontFamily: 'DM Sans', fontWeight: 700, marginBottom: '16px', textAlign: 'left' }}>
                        10% of campaign budget will be directed to OTT Retargeting
                    </p>
                    {['300x250', '300x600', '320x50', '728x90'].map(size => (
                        <div key={size} style={{ marginBottom: '16px' }}>
                            <div
                                style={{
                                    display: 'block',
                                    backgroundColor: 'var(--primary-color)', // Custom color
                                    color: '#fff',
                                    fontFamily: 'DM Sans',
                                    fontWeight: 700,
                                    width: 'auto',
                                    textAlign: 'center',
                                    padding: '10px 20px',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {fileInputRefs[size].current.click()}}
                            >
                                Upload {size} Image
                            </div>
                            <input
                                type="file"
                                ref={fileInputRefs[size]}
                                accept="image/jpeg, image/png"
                                style={{ display: 'none' }}
                                onChange={(e) => handleImageUpload(e.target.files[0], size)}
                            />

                            {errors[size] && <div style={{ color: 'red', marginTop: '8px' }}>{errors[size]}</div>}

                            {progress[size] && (
                                <div style={{ position: 'relative', overflow: 'hidden', height: '4px', backgroundColor: 'var(--primary-color)', width: `${progress[size]}%`, borderRadius: '100px', marginTop: '8px' }}>
                                    <div id="progressSheen"></div>
                                    <p style={{ textAlign: 'center', fontSize: '12px', marginTop: '4px' }}>{Math.floor(progress[size]) + "%"}</p>
                                </div>
                            )}

                            {images[size] && (
                                <div style={{ marginTop: '16px' }}>
                                    <img src={images[size]} alt={`${size} uploaded`} style={{ width: '100%', borderRadius: '4px' }} />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default OTTRetargetingUpload;
