import React, { useState, useEffect } from 'react';
import { storage } from '../firebase/firebaseApp';
import LottieAnimation from '../Lottie/Lottie';
import checkmarkAnim from "../Lottie/59945-success-confetti.json";
import confetti from "../Lottie/98883-confetti.json";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import errorIcon from '../Images/error.svg'
import './uploadBox.css';
const UploadVideo = (props) => {
  const [logo, setLogo] = useState(null);
  const [logoError, setLogoError] = useState(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [expandRequirments, setExpandRequirments] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadState, setUploadState] = useState('waiting');
  const [uploadedVideoURLs, setUploadedVideoURLs] = useState([]);
  const [vidHeight, setVidHeight] = useState(null);
  const [vidWidth, setVidWidth] = useState(null);
  const [vidBitrate, setVidBitrate] = useState(null);
  const [vidDuration, setVidDuration] = useState(null);
  const [vidFileSize, setVidFileSize] = useState(null);
  const types = ['video/mp4'];
  const [uploadProgressMap, setUploadProgressMap] = useState({});

  const handleChange = (e) => {
    let selectedFiles = e.target.files; // Use e.target.files to get an array of files

    if (selectedFiles.length > 0) {
      // Iterate through each selected file
      for (let i = 0; i < selectedFiles.length; i++) {
        let selectedFile = selectedFiles[i];

        if (types.includes(selectedFile.type)) {
          if (selectedFile.size <= 30 * 1024 * 1024) {
            setFile(selectedFile);
            setError(null);
          } else {
            setFile(null);
            setError('File must be 30 seconds or less');
            break; // Exit the loop if one file exceeds the size limit
          }
        } else {
          setFile(null);
          setError('Please select MP4 files only');
          break; // Exit the loop if one file is of an unsupported type
        }
      }
    }
  };

  useEffect(() => {
    if (props.creative.length === 0) {
      setUploadState('waiting');
      setError(null)
    }
  }, [props.creative]);

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    onDrop(Array.from(droppedFiles)); // Convert FileList to array and pass to onDrop
  };
  

  const onDrop = async (files) => {
    setError(null);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = function (event) {
        const video = document.createElement('video');
        video.src = event.target.result;
  
        video.addEventListener('loadedmetadata', function () {
          const height = video.videoHeight;
          const width = video.videoWidth;
          const { duration } = video;
          const fileSizeBytes = file.size;
          const fileSizeMB = fileSizeBytes / 1000000;
          const mbps = (fileSizeBytes * 8) / (duration * 1000000);
  
          if (height / width !== 0.5625) {
            setError("Incorrect Aspect Ratio: Videos must be 16:9");
            return;
          }
          if (height < 1080 || width < 1920) {
            setError("Resolution too low: Minimum acceptable resolution is 1920x1080");
            return;
          }
          if (duration < 15 || duration > 60) {
            setError(`Invalid Duration for ${file.name}: Please upload a 15-60 second video.`);
            return;
          }
          if (mbps < 1.2) {
            setError("Bitrate too low: Video bitrate must be over 1.2 mbps");
            return;
          }
          setVidBitrate(mbps.toFixed(2));
          setVidHeight(height);
          setVidWidth(width);
          setVidDuration(Math.round(duration));
          setVidFileSize(fileSizeMB.toFixed(2));
          uploadFile(file);
        });
      };
  
      reader.readAsDataURL(file);
    }
  };
  


  function uploadLogo(file) {

    const now = new Date();
    const year = now.getFullYear();
    const month = now.toLocaleString('default', { month: 'long' });
    const filePath = `logos/${year}/${month}/${file.name}`;
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {

        // Observe state change events such as progress, pause, and resume
        console.log('Upload is ' + (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + '% done');
        if ((snapshot.bytesTransferred / snapshot.totalBytes) * 100 > 0) {
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error(error);
      },
      () => {
        // Handle successful uploads on complete
        console.log('Upload successful');
        props.completeStepOne()
        // You can get the download URL of the uploaded file
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          setLogo(downloadURL)
          props.handleLogoUpload(downloadURL)
        });

      }
    );
  }


  function uploadFile(file) {
    setUploadProgressMap((prevProgressMap) => ({
      ...prevProgressMap,
      [file.name]: 0,
    }));
  
    const now = new Date();
    const year = now.getFullYear();
    const month = now.toLocaleString('default', { month: 'long' });
    const filePath = `videos/${year}/${month}/${file.name}`;
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgressMap((prevProgressMap) => ({
          ...prevProgressMap,
          [file.name]: progress,
        }));
  
        console.log('Upload is ' + progress + '% done');
        if (progress > 0) {
          setUploadState('uploading');
        }
        setUploadProgress(progress);
      },
      (error) => {
        console.error(error);
      },
      () => {
        console.log('Upload successful');
        setUploadState('done');
  
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUploadedVideoURLs((prevURLs) => [...prevURLs, downloadURL]);
          props.updateCreative('video', downloadURL);
        });
      }
    );
  }
  


  const handleLogoUpload = (event) => {
    const selectedFile = event.target.files[0];
    const fileSize = selectedFile.size / 1024 / 1024; // Convert to MB

    if (fileSize > 2) {
      setLogoError('Please upload an image that is under 2MB');
      setLogo(null);
    } else {
      uploadLogo(selectedFile)
      setLogoError(null);
    }
  };


  const handleDragOver = (e) => {
    e.preventDefault();
    console.log(e.dataTransfer.items[0])
    if (e.dataTransfer.items[0].kind === "file" && e.dataTransfer.items[0].type === "video.mp4") {
      e.dataTransfer.dropEffect = "copy";
    }
    // const allFilesAreJson = Array.from(e.dataTransfer.items).every(
    //   (item) => item.kind === "file" && item.type === "application/json"
    // );
    // if (allFilesAreJson) {
    //   e.dataTransfer.dropEffect = "copy";

    // } else {
    //   e.dataTransfer.dropEffect = "none";

    // }
  };


  async function isValidUrl(url) {
    const pattern = /^(https?:\/\/)?(www\.)?[a-z0-9]+\.[a-z]{2,}(\/\S*)?$/i;
    const isValidUrl = pattern.test(url);

    if (!isValidUrl) {
      return false;
    }

    try {
      const response = await fetch(url, { method: 'HEAD' });
      console.log(true)
      return response.ok;
    } catch (error) {
      console.log(false)
      return false;
    }
  }


  return (
    <div style={{ display: 'block' }}>
      {uploadState === 'done' ?
        <div>
          <div style={{ position: 'absolute', bottom: '0px', pointerEvents: 'none' }}>
            <LottieAnimation animationData={confetti} height={'auto'} width={'100%'}></LottieAnimation>
          </div>
          {props.creative.map((vid) => {
            return (
              <div style={{position:'relative'}}>
                <div className="closeXbtn" onClick={()=>{props.removeCreative(vid.url)}}>×</div>
              <video src={vid.url} controls style={{ width: '100%', borderRadius: '8px' }}></video>
              </div>
            );
          })}
          <div className="logo-upload-wrapper">
            <div style={{ display: logo ? 'none' : 'block' }}>
              <label htmlFor="logo-upload">Upload Logo</label>
              <input type="file" id="logo-upload" accept="image/*" onChange={handleLogoUpload} />
            </div>
            {logoError && <div className="error">{logoError}</div>}
            {logo &&
              <div>
                <p>Logo:</p>
                <img src={logo} style={{ width: '100px' }} alt="logo"></img>
              </div>
            }
          </div>
        </div> : null
      }
      {uploadState === 'prepping' ?
        <div>
          <strong style={{ width: '100%', textAlign: 'center', textDecoration: 'none' }}>{"Analyzing Upload - One Moment Please... "}</strong>
        </div> : null
      }
      {uploadState === 'uploading' ?
        <div>
          {Object.keys(uploadProgressMap).map((fileName) => (
            <div key={fileName}>
              <p>{fileName}</p>
              {/* <progress value={uploadProgressMap[fileName]} max={100} /> */}
              <div style={{ position: 'relative', overflow: 'hidden', height: '4px', backgroundColor: 'var(--primary-color)', width: uploadProgressMap[fileName] + '%', borderRadius: '100px' }}>
                <div id="progressSheen"></div>
              </div>
              <p>{ Math.floor(uploadProgressMap[fileName]) + "%"}</p>
            </div>
          ))}
        </div> : null
      }
      {uploadState === "waiting" ?
        <div
          className="main-upload"
          style={{}}
          onDragOver={handleDragOver}
          onDragLeave={() => { }}
          onDrop={handleDrop}
        >
          <label id="uploadLabel" htmlFor="fileInput">Upload up to 3 videos</label>
          <input
            id="fileInput"
            type="file"
            accept=".mp4"
            multiple
            onChange={(e) => onDrop(e.target.files)}
          />
        </div> : null
      }
      {error ?
        <div style={{ display: 'flex', alignContent: 'center', gap: '8px', marginTop: '10px' }}>
          <img src={errorIcon} style={{ width: '24px' }} alt="error"></img>
          <div className="error-msg">{error}</div>
        </div> : null
      }
      {uploadState === "uploading" ?
        <div className="upload-parse-container">
          <div className="upload-parse-success">
            <div className="lottie-parse-check">
              <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation>
            </div>
            <div>Resolution</div>
            <div className="fileSpec-attr">{vidWidth + "x" + vidHeight}</div>
          </div>
          <div className="upload-parse-success">
            <div className="lottie-parse-check">
              <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation>
            </div>
            <div>Duration</div>
            <div className="fileSpec-attr">{vidDuration + "s"}</div>
          </div>
          <div className="upload-parse-success">
            <div className="lottie-parse-check">
              <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation>
            </div>
            <div style={{ textAlign: 'center' }}>Bitrate</div>
            <div className="fileSpec-attr">{vidBitrate + " mbps"}</div>
          </div>
          <div className="upload-parse-success">
            <div className="lottie-parse-check">
              <LottieAnimation animationData={checkmarkAnim} height={'60px'} width={'60px'}></LottieAnimation>
            </div>
            <div>File Size</div>
            <div className="fileSpec-attr">{vidFileSize + " MB"}</div>
          </div>
        </div> : null
      }
      {uploadState === "waiting" ?
        <div>
          {!expandRequirments ?
            <div style={{ paddingTop: '20px' }}>
              <strong onClick={() => { setExpandRequirments(true) }} style={{ textDecoration: 'underline' }}>Show Video Specs</strong>
            </div> :
            <div style={{ paddingTop: '20px' }}>
              <strong onClick={() => { setExpandRequirments(false) }} style={{ textDecoration: 'underline' }}>Hide Video Specs: </strong>
              <p>Accepted File Types: MP4</p>
              <p>Minimum Resolution: 1920x1080 </p>
              <p> Maximum File Size: 50 MB </p>
              <p>Duration 15-30 seconds</p>
              <p>*Note 15 or 30 second commercials get priority placement*</p>
              <p>Bitrate: Minimum of 19000 kbps</p>
            </div>

          }
        </div> : null
      }


    </div>
  );
};

export default UploadVideo;