import React, { useState, useEffect } from 'react';
import { Link } from "react-scroll";
import streamwavelogo from './Images/Streamwave_Blk.png';
import brandBottom from './Images/brand_bottom.png';
import brandTop from './Images/brandtop.png';
import FAQ from './FAQ/FAQ.js';
import couch from './Images/couch2.png';
import hero from './Images/hero1.png';
import bullseye from './Images/bullseye.svg';
import megaphone from './Images/megaphone.svg';
import budget from './Images/budget.png';
import targeting from './Images/targeting.png';
import upload from './Images/upload.png';
import networks from './Images/Networks_v1.png';
import Industries from './Industies/Industries.js'
import track from './Images/track.png';
import local from './Images/hyperLocal.png';
import mega from './Images/brandrecognition.png';
import streamwave from './Images/Streamwavelogo.svg';

import './App.css';

export default function Home() {
    const [menuVisible, setMenuVisible] = useState(false);
    const [showAdForm, setShowAdForm] = useState(false);
    const [referralId, setreferralId] = useState(null);
    const faqData = [
        {
            question: "What are the benefits of using Connected TV advertising for my business?",
            answer: "Let's break it down! Here are the key benefits of using Connected TV (CTV) advertising for your business: \n\n Get Personal: With CTV, you can target your ads to specific audiences based on their demographics, interests, and viewing habits. This means your ads are tailored to your audience, making them more relevant and engaging. And you know what that means - better engagement leads to higher conversion rates! \n\nGo Big: CTV advertising lets you reach a wider audience by advertising on smart TVs, computers, and mobile devices. That means your ad can be seen by viewers anytime, anywhere, no matter what they're watching. So you can sit back and relax, knowing your message is getting out there to the masses! \n\nEyes on the Prize: CTV ads tend to have higher viewability rates and longer view times compared to other forms of advertising. Why? Because viewers are more engaged when they're watching streaming content, and they're less likely to skip or ignore ads. And that means more eyes on your message! \n\nSave Money: CTV advertising can be more cost-effective than traditional TV advertising, as you only pay for impressions served to your target audience. So you can get more bang for your buck and maximize your ROI. Cha-ching! \n\nMeasure Up: With CTV advertising, you can track and measure the success of your ad campaigns in real-time. This means you can see how many impressions you're getting, how many people are clicking through to your website, and how many are converting into customers. And that's the name of the game, folks - measurable results for your business!",
        },
        {
            question: 'What is the cost of Connected TV advertising?',
            answer: "The cost of advertising on connected TV (CTV) can vary depending on several factors, such as the size of the audience, the type of content being advertised, and the specific platform being used. In general, CTV advertising tends to be less expensive than traditional TV advertising, but more expensive than digital advertising. \n\nStreamwave usually pays for CTV ads is through a CPM (cost per thousand impressions) model, where they pay a set amount for every thousand times their ad is displayed to viewers. This can range anywhere from a few dollars to upwards of $30 or more, depending on the specific targeting options and ad inventory being used.",
        },
        {
            question: 'How does Connected TV differ from traditional TV advertising?',
            answer: "Traditional media buying is like buying a one-time ad spot on your favorite TV or radio show - think a restaurant ad during the evening news or a radio spot during your favorite morning talk show. The ad airs for a set duration and is shown to whoever happens to be watching or listening at that time. \n\n CTV media buying, on the other hand, is all about advertising on streaming services available on smart TVs, computers, or mobile devices. With CTV, we can display your ad to specific individuals based on their interests, demographics, and viewing habits. This results in a more personalized and targeted approach to advertising. Plus, CTV ads run 24/7, so your ad can be seen by viewers anytime, anywhere, no matter what they're watching! \n\n So there you have it - while traditional media buying is like a one-time ad spot, CTV media buying is like having your own personal ad tailored to your ideal audience and running around the clock.",
        },
        {
            question: 'How do I get started with Connected TV advertising?',
            answer: "Starting with Streamwave is a breeze - we don't ask for contracts, deposits or commitments. Just pay for your media buy and let us handle the rest! But don't forget to bring your A-game: a top-notch video or audio spot, business info and objectives, and of course, your trusty credit card.",
        },
        {
            question: 'Is Streamwave free to use?',
            answer: "At Streamwave, we like to keep things simple - and that includes our fees! Unlike those stuffy old advertising agencies and traditional partners, we don't charge any upfront fees. Nope, not a dime. That's because we've automated the CTV media buying process to be as easy and efficient as possible. And as more and more businesses use our platform, it just keeps getting better and better! \n\nSo how do we make our money, you ask? Well, it's easy - we take a percentage of your media buy. It's just a little cut that helps keep our business growing, pay for data fees, and feed our families (hey, we've got to eat too!). If you want to learn more about the nitty-gritty, you can check out our Terms & Conditions at this handy-dandy link. \n\n See? Easy-peasy. At Streamwave, we're all about making things simple, affordable, and effective for your business. So come on board and let's grow together!",
        },
        {
            question: 'How do I view my campaign performance data?',
            answer: "Ready for some data-driven insights to knock your socks off? At the end of each campaign, we'll send you a report that breaks down all the good stuff - from impressions to CPM, and even where your ad was seen the most. We're talking juicy, action-packed insights that'll make your head spin! The report will be emailed directly to your business point of contact, so keep an eye on your inbox. LET'S GO, data nerds!",
        },
        {
            question: 'Does Streamwave offer other advanced targeting or retargeting options for my ads?',
            answer: "Heck yeah, we're not just a one-trick pony! We've got four main strategies to help businesses achieve their ad objectives - and they're all pretty awesome. Here's the lowdown:\n\n1) Data Targeting - This is our self-serve model, perfect for businesses new to Streamwave.\n\n2) Pixel Retargeting - Place a snippet of code on your website and watch as visitors are added to your retargeting audience.\n\n3) Terrestrial Foot Traffic - Ready for some fun? Capture physical customers who visit your location and retarget them using their *MAID.\n\n4) Conquesting - This guerrilla marketing strategy involves geofencing your competition's location and targeting their customers. Auto dealerships, we're looking at you.\n\nNow, some of these strategies involve a bit more human involvement (and maybe some extra $$), but trust us, they're worth it. Contact Streamwave support or schedule a demo HERE to learn more. Let's get creative and crush those ad objectives!\n\n*A MAID (Mobile Ad ID) is a special number that is used to identify a mobile device, like a smartphone or tablet. This number helps advertisers and marketers like us to understand more about how people use their mobile devices and what kind of ads they might be interested in.",
        },
        {
            question: 'What is Connected TV advertising?',
            answer: "Connected TV (CTV) refers to the ability to access television content over an internet connection rather than traditional broadcast methods, such as cable or satellite. Essentially, it's like watching TV using the internet instead of a physical cable or satellite connection. CTV allows viewers to access a wide range of content, including TV shows, movies, and even live events, through apps or streaming services like YouTube TV, Hulu, and Amazon. Some popular devices that enable CTV include smart TVs, streaming sticks like Roku or Amazon Fire TV, and gaming consoles like Xbox and PlayStation. \n\n One of the biggest advantages of CTV is its flexibility and convenience. With traditional cable or satellite TV, viewers are often limited to a set schedule of programming, and they may not be able to access certain shows or channels. With CTV, viewers have more control over what they watch and when they watch it. They can choose from a wider range of programming and watch it at their own convenience. \n\nOverall, connected TV is a great option for those who want more control over their TV viewing experience and access to a wider range of content. It's an increasingly popular choice for people of all ages and backgrounds, and it's likely to become even more prevalent in the years to come.",
        },
        {
            question: "Why don't I just go through comcast or other cable companies I might already work with?",
            answer: `Lots of businesses in the US work with big-time media buyers like Comcast or Frontier Communications. They say they've got "streaming ad options," but here's the deal: these options are NOT the same as CTV. Nope, these ads are still stuck in the "Comcast" world, only showing up on cable boxes for their customers. It's like buying a fancy steak dinner when all you really wanted was a burger. \n\n So don't get fooled by those sneaky traditional media buyers! If you really want to reach a wider audience and make a splash in the world of streaming, you gotta go CTV.`,
        },
        {
            question: 'Can I control which channels my ads are seen on?',
            answer: "Well, that's a tricky one. If you're a streaming TV enthusiast in the area where you've placed your ad, chances are you'll catch a glimpse of it. But with CTV, ad placements are determined by complex targeting capabilities and data. \n\nUnlike traditional or fragmented media buying, where you purchase ad spots on specific channels at certain times, Streamwave serves ads 24/7 across multiple networks. But rest assured, we'll only show your ad to your ideal audience profile, leading to better results.",
        },
        {
            question: 'Does Streamwave have a minimum spend requirement?',
            answer: "Yes, we do. When it comes to this biz, you know the saying—‘you get what you pay for.’ That’s why we’re all about dishing out some top-notch advice to make sure you get the best bang for your buck. Here at Streamwave, we recommend following a few basic best practices to maximize your results and ROI. \n\nFirst things first, we enforce having a minimum advertising budget of $2,500 for a recommended 30-day ad flight. We won’t restrict you to a specific timeframe for your ad, but sticking to this minimum budget will give you some serious data learning and delivery prowess. Think about it—it’s like investing a cool $80 per day to make your ad dreams come true! \n\nWhy do we enforce this minimum? Simple! We want you to squeeze every drop of value out of your investment. Trust us, a little extra now will pay off big time down the road. Plus, our team of experts is always ready to lend a helping hand, optimizing your ad strategy and making sure you’re maximizing your return on investment.",
        },
        {
            question: 'How does advertising on streaming services compare to advertising on social media?',
            answer: "CTV advertising is a type of video advertising that is shown on streaming services available on smart TVs, computers, or mobile devices. With CTV advertising, you can target specific individuals based on their demographics, interests, and viewing behaviors, which allows for a more personalized and relevant approach to advertising. CTV ads also tend to have higher viewability rates and longer view times compared to other forms of advertising. \n\n Social media advertising, on the other hand, involves advertising on social media platforms such as Facebook, Instagram, Twitter, and LinkedIn. Social media advertising allows for targeting based on a user's profile information, interests, and behaviors, and also enables advertisers to run ads in a variety of formats such as image, video, carousel, and more. Social media advertising tends to be more cost-effective than CTV advertising, but the viewability rates and engagement may vary depending on the platform and the ad's quality. \n\n Overall, CTV advertising and social media advertising each have their own strengths and weaknesses, and the best choice depends on your business goals and target audience. CTV advertising may be more effective for brands that want to reach a specific audience through video advertising, while social media advertising may be a better fit for brands that want to reach a wider audience with a mix of ad formats.",
        },
        {
            question: 'How can I ensure that my ads are being shown on quality programming?',
            answer: "Don't you worry - we wouldn't dream of putting your ad on any sketchy networks. At Streamwave, we only work with the best of the best, networks that are part of some of the largest media companies in the US. However, we understand that some businesses may have strong opinions about certain networks, and that's totally okay. Just let us know in the additional notes section during the media buy process, and we'll take it from there.",
        },
        {
            question: "Where can I get video content made if i don't have it already?",
            answer: "When it comes to finding the perfect video production company, there are more options than you can shake a stick at! Our top pick? Go local, baby! We're all about supporting local businesses and there's no shortage of talented video production companies in your neck of the woods. So give your local Googler a workout and find a nearby video production company that you can work with. \n\n But let's say you're on a tight budget or you just can't seem to find the right local fit. Fear not, my friend - the internet is your oyster! Fiverr is a fantastic online creative community where you can hire freelance designers to whip up videos for you. And let us tell you, these Fiverr folks are a talented bunch! They're easy to work with and you can find just about anyone from anywhere in the world to fit your budget and needs. \n\n Just keep in mind that you get what you pay for, so it's worth it to find someone who's middle of the road in terms of price. After all, just like a good plumber or carpenter, you want someone who knows their stuff and won't leave you high and dry. \n\n And if you're still in need of some video production resources, we've got you covered. Check out the links below for some more online communities like Fiverr that can help you get the perfect video for your business.",
        },
        
        
    ];
    const openHamburgerMenu = () => {
        setMenuVisible(!menuVisible)
    }

    

    useEffect(() => {
        const url = new URL(window.location);
        const referralIdParam = url.searchParams.get('referral_id');
        const scrollAudiences = url.searchParams.get('market_pulse');
        const scrollFAQ = url.searchParams.get('faq');
      
        const scrollToElement = (elementId) => {
          const targetElement = document.getElementById(elementId);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        };
      
        const runEffectWithDelay = () => {
          if (scrollFAQ) {
            scrollToElement('faq-anchor');
          }
      
          if (scrollAudiences) {
            scrollToElement('mp');
          }
      
          if (referralIdParam) {
            setreferralId(referralIdParam);
          }
        };
      
        const timeoutId = setTimeout(runEffectWithDelay, 500);
      
        // Clean up the timeout if the component unmounts before the timeout fires
        return () => clearTimeout(timeoutId);
      }, []);
      
    

    return (
        <div className="container">
            <header>
                <img className="brand" src={streamwavelogo} alt="streamwave"></img>
                <div style={{ display: 'grid', height: '30px', width: '30px', placeContent: 'center', padding: 0 }} onClick={() => { openHamburgerMenu() }}>
                    <button className="menu-toggle">
                        <span className="hamburger"></span>
                    </button>
                </div>
                {menuVisible ?
                    <div className="vert-menu">
                        <div className='vert-menu-spacer'>
                            <Link to="how-it-works" smooth={true} duration={500}>
                                How it Works
                            </Link>
                        </div>
                        <div className='vert-menu-spacer'>
                            <Link to="faq" smooth={true} duration={500}>
                                FAQ
                            </Link>
                        </div>
                        <div className='vert-menu-spacer'>
                            <Link to="benefits" smooth={true} duration={500}>
                                Why Streaming?
                            </Link>
                        </div>
                        <div className='vert-menu-spacer'>
                            <Link to="networks" smooth={true} duration={500}>
                                Networks
                            </Link>
                        </div>
                        <div className='vert-menu-spacer'>
                            <Link to="segmenation" smooth={true} duration={500}>
                                Data Targeting
                            </Link>
                        </div>
                        <div className="RequestDemo" style={{marginBottom:'16px'}} onClick={() => { window.location.href = "https://calendly.com/streamwave/meet-streamwave?month=2023-04" }}>
                            <div>Get a Demo</div>
                        </div>
                        <div onClick={() => {if(referralId !==null){window.location.href = "/launchad?referral_id="+referralId}else{window.location.href = "/launchad"} }} className="CreateAdNav">
                            <img src={megaphone} style={{ width: '24px', marginRight: '6px' }} alt="megaphone"></img>
                            <div>Launch Ad</div>
                        </div>
                    </div> : null
                }

                <nav>
                    <ul>
                        <div className="nav-spacer">
                            <Link to="how-it-works" smooth={true} duration={500}>
                                How it Works
                            </Link>
                        </div>
                        <div className="nav-spacer">
                            <Link to="faq" smooth={true} duration={500}>
                                FAQ
                            </Link>
                        </div>
                        {/* <div className="nav-spacer">
                            <Link to="benefits" smooth={true} duration={500}>
                                Why Streaming?
                            </Link>
                        </div> */}
                        <div className="nav-spacer">
                            <Link to="networks" smooth={true} duration={500}>
                                Networks
                            </Link>
                        </div>

                        <div className="RequestDemo" onClick={() => {window.location.href = "https://calendly.com/streamwave/meet-streamwave?month=2023-04"}}>
                            <div>Get a Demo</div>
                        </div>

                        <div className="CreateAdNav" onClick={() => {if(referralId !==null){window.location.href = "/launchad?referral_id="+referralId}else{window.location.href = "/launchad"}}}>
                            <img src={megaphone} style={{ width: '24px', marginRight: '6px' }} alt="megaphone"></img>
                            <div style={{marginTop:'1.5px'}}>Launch Ad</div>
                        </div>
                    </ul>
                </nav>
            </header>

            {/* HERO SECTION */}
            <section className="hero">
                <div className="hero-text">
                    <h1 className="hero-heading">Advertise your small business on the big screen</h1>
                    <p className="hero-subheading">Streamwave unlocks the advertising  power of streaming TV for your local brand. Simply upload your 15-30 second commercial, set your targeting objectives, and let us take care of the rest.</p>
                    <div className="hero-button" style={{ display: 'flex' }} onClick={() => {if(referralId !==null){window.location.href = "/launchad?referral_id="+referralId}else{window.location.href = "/launchad"}}}>
                        <img src={bullseye} style={{ width: '24px', marginRight: '8px', marginLeft: '0px' }} alt="bullseye"></img>
                        <div>Start Advertising</div>
                    </div>
                    {/* <button className="hero-button">Start Advertising</button> */}
                    {/* <p>No Account Needed</p> */}
                </div>
                <img src={hero} id="masthead-img" alt="mast-head-hero"></img>
            </section>
            <section>
                <div className="brand-clip">
                    <img className="brandTop" src={brandTop} alt="brand-top" />
                </div>
                <div className="brand-clip">
                    <img className="brandBottom" src={brandBottom} alt="brand-bottom" />
                </div>
            </section>
            {/* HOW IT WORKS SECTION */}
            <section className="how-it-works">
                <div className="section-header">
                    <h2 className="section-heading">How It Works</h2>
                    <p className="section-subheading">Streamwave takes the hassle out of launching your streaming TV ad campaigns</p>
                </div>
                <div className="steps">
                    <div className="step">
                        <img src={upload} className='howitworks-icons' alt="Upload Icon" />
                        <div className="step-number">1</div>
                        <h3>Upload</h3>
                        <p>Upload your 15-30 second audio or video commercial to our platform.</p>
                    </div>
                    <div className="step">
                        <img src={targeting} className='howitworks-icons' alt="Settings Icon" />
                        <div className="step-number">2</div>
                        <h3>Choose Audiences</h3>
                        <p>Select Industry-Specific Audience Segments to Fuel Your Unique Marketing Objectives</p>
                    </div>
                    <div className="step">
                        <img src={budget} className='howitworks-icons' alt="Budget Icon" />
                        <div className="step-number">3</div>
                        <h3>Set Budget</h3>
                        <p>Set your budget for the campaign and launch your ad.</p>
                    </div>
                    <div className="step">
                        <img src={track} className='howitworks-icons' alt="Budget Icon" />
                        <div className="step-number">4</div>
                        <h3>Track Performance</h3>
                        <p>Once you complete your ad buy you will receive weekly email updates on your campaigns performance.</p>
                    </div>
                </div>

            </section>

            <section className="segmenation" id="mp">
                <div className="section-header">
                </div>
                <div className="targeting-main" style={{gap: '10px', alignContent: 'center' }}>
                    <div>
                        <h2 className="section-heading">Big Data + Connected TV</h2>
                        <p className="section-subheading" style={{ width: '100%', padding: 0, marginBottom:'20px' }}>Streamwave offers carefully crafted MarketPulse audience segments tailored to your industry, delivering precise targeting for your primary customers within your local market. Maximize your ROAS by reaching cord-cutting customers with precision. Explore our MartetPulse audience segments by choosing an industry below. </p>
                        <h3 style={{fontFamily:'DM Sans', color:'var(--primary-color)'}}>Explore MarketPulse Audiences</h3>
                        <Industries updateIndusties={()=>{}} updateAudiences={()=>{}}></Industries>
                    </div>
                    <img className="couchimg" src={couch} alt="couch"></img>
                </div>
            </section>
            {/* BENEFITS SECTION */}
            <section className="benefits">
                <div className="section-header">
                    <h2 className="section-heading">Why Streaming?</h2>
                    <p className="section-subheading">Streaming is the <strong>future</strong> of advertising. Here's why.</p>
                </div>
                <div className="benefits-container">
                    <div className="benefit">
                        <img style={{ width: '240px' }} src={local} alt="Hyper-Local Targeting Icon" />
                        <h3>Hyper-Local Targeting</h3>
                        <p>Advertising on premium streaming platforms allows you to target your ideal audience with hyper-local precision, increasing your brand presence and value in your local area.</p>
                    </div>
                    <div className="benefit">
                        <img style={{ width: '240px' }} src={mega} alt="Increase Brand Recognition Icon" />
                        <h3>Increase Brand Recognition</h3>
                        <p>Research shows that viewers of streaming content are more likely to recall ads and have a positive perception of brands that advertise on these platforms, making it a highly effective and engaging way to boost your brand's recognition, relevance, and overall success.</p>
                    </div>
                </div>
            </section>
            {/* Networks SECTION */}
            <section className='networks'>
                <div className="section-header">
                    <h2 className="section-heading">Targeted Advertising Across 40+ Premium Networks</h2>
                    <p className="section-subheading">Streamwave offers an easy way to connect with your target audience, whether you're advertising locally, regionally, or globally. Our platform lets you create customized audience segments that deliver results. You can reach viewers on over 40 premium networks, making it simple to grow your business and expand your mixed media reach.</p>
                </div>
                <img src={networks} alt="networks" style={{display:'block'}} className="networks-img"></img>
            </section>
            {/* FAQ SECTION */}
            <section className='faq-mast'>
                <div className="section-header">
                    <h2 className="section-heading" id="faq-anchor">Frequently Asked Questions</h2>
                </div>
                <FAQ faqData={faqData} />
            </section>
            <footer style={{textAlign:'center'}}>
                <a href="https://sites.google.com/streamwave.io/termsofservice/home" style={{marginRight: '20px'}}>Terms of Service</a>
                <a href="https://sites.google.com/streamwave.io/privacypolicy">Privacy Policy</a>
            </footer>
            <div style={{ height: '400px' }}></div>
        </div>
    )
}
