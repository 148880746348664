import React, { useState, useEffect, useRef } from 'react';
import { FaPaperPlane, FaMicrophone } from 'react-icons/fa/index.esm.js';
import { MdAudiotrack } from "react-icons/md/index.esm.js";
import { IoIosCloseCircle } from "react-icons/io/index.esm.js";
import { IoMicOutline } from "react-icons/io5/index.esm.js";
import AudioEditor from './AudioEditor.js';
import TypingIndicator from './TypingIndicator.js'
import styles from './advoice.module.scss';


const AdVoice = ({assistant_id, name, logo, headerColor}) => {
  const [showEditor, setShowEditor] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [isVoice, setIsVoice] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [audioPrompt, setAudioPrompt] = useState("");
  const [threadID, setThreadID] = useState(null);
  const [newMessageInput, setNewMessageInput] = useState(''); // Renamed to avoid conflict
  const chatPaneRef = useRef();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendUserMessageToOpenAI(newMessageInput);
      const newMessage = {
        role: 'user',
        content: [{ text: { value: newMessageInput } }],
        created_at: Date.now(),
      };
      setAllMessages((prevMessages) => [...prevMessages, newMessage]);
      setNewMessageInput('');
    }
  };

  useEffect(() => {
    let mediaRecorder;
    let socket;

    const startTranscription = (stream) => {
      mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
      socket = new WebSocket('wss://api.deepgram.com/v1/listen', [
        'token',
        '4f252498671c00f9f0d6f80008e2b33e2b24c838',
      ]);

      socket.onopen = () => {
        mediaRecorder.addEventListener('dataavailable', (event) => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.send(event.data);
          }
        });
        mediaRecorder.start(250);
      };

      socket.onmessage = (message) => {
        const received = JSON.parse(message.data);
        const newWords = received.channel.alternatives[0].words;

        if (newWords !== "") {
          const newTranscript = newWords.map(word => word.word).join(' ');
          // Update the transcript state variable
          setNewMessageInput(prevTranscript => prevTranscript + ' ' + newTranscript);
        }

      };
    };

    const stopTranscription = () => {
      if (mediaRecorder && mediaRecorder.state === 'recording') {
        mediaRecorder.stop();
      }

      if (socket && (socket.readyState === WebSocket.OPEN || socket.readyState === WebSocket.CONNECTING)) {
        socket.close();
      }
    };

    if (isVoice) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        startTranscription(stream);
      });
    } else {
      stopTranscription();
    }

    return () => {
      // Cleanup code, stop transcription when the component is unmounted or isVoice changes.
      stopTranscription();
    };

  }, [isVoice]);




  const sendUserMessageToOpenAI = async (msg) => {
    setIsGenerating(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_AD_VOICE_BACKEND_URL}/api/openai`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt: msg,
          max_tokens: 100,
          thread_id: threadID,
          assistant_id: assistant_id
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }

      const responseData = await response.json();

      setThreadID(responseData.message.data[0].thread_id);
      const newMessages = Array.isArray(responseData.message.data) ? responseData.message.data : [];

      setAllMessages(newMessages.sort((a, b) => a.created_at - b.created_at));
      setIsGenerating(false)
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  useEffect(() => {
    if(chatPaneRef.current){
      chatPaneRef.current.scrollTop = chatPaneRef.current.scrollHeight;
    }
    
  }, [allMessages]);

  const handleAudioClick = (message) => {
    setAudioPrompt(message)
    setShowEditor(true)
  }

  const returnToChat = () => {
    setShowEditor(false)
  }

  const handleVoiceStart = () => {
    setIsVoice(true)
  }

const handleVoiceEnd = () => {
    setIsVoice(false)
  }

  return (
    <div>
      {isCollapsed ?
        <div className={styles.smallControl} onClick={()=>{setIsCollapsed(false)}}>
          <img className={styles.logoCollapsed} src={logo}></img>
        </div>:

        <div className={styles.chatPane}>
          {!showEditor ? (
            <>
              <div className={styles.header} style={{background:headerColor}}>
                <div className={styles.logoContainer}>
                  <img className={styles.logo} src={logo}></img>
                </div>
                <h3 style={{ textAlign: 'left' }}>{name}</h3>
                <IoIosCloseCircle style={{ fontSize: '22px', marginTop: '10px', marginRight: '16px' }} onClick={() => { setIsCollapsed(true) }} />
              </div>
              <div className={styles.messages} ref={chatPaneRef}>
                {allMessages.length === 0 ?
                  <div className={styles.FREcontainer}>
                    <h2>Generate Professional Voiceovers</h2>
                    <p style={{ opacity: .8 }}>Discover the power of AdVoice AI – your partner in crafting professional voiceovers. I guide you through script creation and seamlessly generate human-like voiceovers with ease.</p>
                  </div> : null
                }
                {Array.isArray(allMessages) ? (
                  allMessages.map((message, index) => (
                    <div className={styles.msgContainer} key={index}>
                     
                      <div
                        className={message?.role === 'user' ? styles.userMessage : styles.botMessage}
                        dangerouslySetInnerHTML={{ __html: message?.content[0]?.text?.value }}
                      />
                      {message.role === "assistant" ?
                        <div className={styles.audioIcon} onClick={() => { handleAudioClick(message) }}>
                          <MdAudiotrack style={{ color: 'white' }} />
                        </div> : null
                      }
                    </div>
                  ))
                ) : (
                  <p>Data is not an array.</p>
                )}
                {isGenerating ? 
                <TypingIndicator/>:null
                }
                 
              </div>
              {isVoice ?
                <div className={styles.listeningTxt}>Start talking...</div> : null
              }

              <div className={styles.inputContainer}>
                <div className={styles.innerInput} >
                  <input
                    type="text"
                    placeholder="Type your message..."
                    value={newMessageInput}
                    onChange={(e) => setNewMessageInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  {!isVoice ?
                    <div className={styles.micContainer} onClick={handleVoiceStart} onMouseUp={handleVoiceEnd}>
                      <IoMicOutline className={styles.unfilledMic} style={{ fontSize: '22px' }} />
                    </div> : <div className={styles.micContainer} onClick={handleVoiceEnd}>
                      <FaMicrophone className={styles.filledMic} style={{ color: 'var(--brand-color)', fontSize: '18px' }} />
                    </div>
                  }

                </div>
                <button className={styles.sendBtn} onClick={() => {
                  sendUserMessageToOpenAI(newMessageInput);
                  const newMessage = {
                    role: 'user',
                    content: [{ text: { value: newMessageInput } }],
                    created_at: Date.now(),
                  };
                  setAllMessages((prevMessages) => [...prevMessages, newMessage]);
                  setNewMessageInput('');
                }}>
                  <FaPaperPlane />
                </button>
              </div>
            </>
          ) : (
            <AudioEditor prompt={audioPrompt} returnToChat={returnToChat} />
          )}
        </div>
      }
    </div>
  );
};

export default AdVoice;
