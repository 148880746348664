import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {getStorage} from 'firebase/storage'
import firebaseConfig from './firebaseConfig';

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app)
const storage=getStorage(app)
export { app, auth, db, functions, storage};
