import React, { useState, useEffect } from 'react';
import radioChecked from '../Images/radio_cheked.svg';
import radioUnchecked from '../Images/radio_unchecked.svg';
import * as XLSX from 'xlsx';
import './audiences.css';

function Audiences(props) {
    const [data, setData] = useState([]);
    const [selectedAudiences, setSelectedAudiences] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(props.industry.value, {
                headers: {
                    Authorization: 'Basic ' + btoa('username:password')
                }
            });
            const arrayBuffer = await response.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(worksheet);
            let audienceData = []
            sheetData.forEach((item) => {
                if (item.A) {
                    audienceData.push(item.A)
                }
            })
            setData(audienceData);
        };

        fetchData();
    }, []);

    const updateSelectedAudiences = (audience) => {
        let prevSelectedAudiences = [...selectedAudiences]
        if (prevSelectedAudiences.includes(audience)) {

            //remove the selected audience
            let remainingAudiences = prevSelectedAudiences.filter((value) => value !== audience)
            setSelectedAudiences(remainingAudiences)
            props.updateSelectedAudiences(remainingAudiences)
        } else {
            prevSelectedAudiences.push(audience)
            setSelectedAudiences(prevSelectedAudiences)
            props.updateSelectedAudiences(prevSelectedAudiences)
        }
    }

    return (
        <div>
            {data.length > 0 ?
                <div id="audiences-container">
                    <div style={{ paddingLeft: '10px', fontWeight: 600, fontSize: '14px' }}>{props.industry.label + " audiences"}</div>
                    {data.map((audience, index) => (
                        <div key={index} className='audience-item' onClick={() => { updateSelectedAudiences(audience) }}>
                            {selectedAudiences.includes(audience) ?
                                <img className="audience-radio" src={radioChecked} alt="radio-btn"></img> : <img className="audience-radio" src={radioUnchecked} alt="radio-btn"></img>
                            }
                            <div>{audience}</div>
                        </div>
                    ))}

                </div> : null}
        </div>

    );
}

export default Audiences;
