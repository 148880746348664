import { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Streamwave from '../Images/Streamwave_Blk.png';
import './ThankYouPage.css';
import { db } from '../firebase/firebaseApp';
import LottieAnimation from '../Lottie/Lottie';
import confetti from '../Lottie/98883-confetti.json';

const ThankYouPage = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    let isMounted = true; // Flag to track component mounting

    const getCampaign = async (id) => {
      try {
        const docRef = doc(db, 'campaigns', id);
        const docSnapshot = await getDoc(docRef);
        
        if (docSnapshot.exists() && isMounted) { // Check if component is still mounted before updating state
          const campaignData = docSnapshot.data();
          setData(campaignData);
          console.log(campaignData);
          updateOpportunity(campaignData.salesforce_opportunity_id , campaignData, id);
        } else {
          console.log('Document not found');
        }
      } catch (error) {
        console.error('Error fetching campaign:', error);
      }
    };

    const url = new URL(window.location);
    const campaign = url.searchParams.get('campaign');

    if (campaign && isMounted) { // Check if component is still mounted before calling getCampaign
      getCampaign(campaign);
    }

    return () => {
      isMounted = false; // Cleanup function to cancel pending async tasks if component unmounts
    };
  }, []);

  const updateOpportunity = async (sf_id, campaign, docId) => {

    try {
      const response = await fetch(
        `https://hook.us1.make.com/wmhm1f2r28ouebxawt7myikwjzdav493?stage=Ready for Deployment&documentId=${docId}&name=${campaign.campaign.contact.first_name+" "+campaign.campaign.contact.last_name}&companyName=${campaign.campaign.campaign.company_name}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sf_id: sf_id }),
        }
      );
        console.log(response)
      if (!response.ok) {
        console.log(response)
        throw new Error('Error sending webhook');
       
      }else{
        console.log('Webhook sent successfully');
      }
  
      
      if (campaign.referral_id && campaign.referral_commission > 0 && campaign.payment_status==="Pending") {
        createTransfer(campaign.referral_id, campaign.referral_commission);
      }else{
        //console.log("Transfer Stopped")
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };  

  const updateCampaignDocument = async (id) => {
    try {
      const docRef = doc(db, 'campaigns', data.documentId);
      await updateDoc(docRef, { payment_status: 'complete' }); // Update the 'processed' field to true
      console.log('Campaign document updated successfully');
    } catch (error) {
      console.error('Error updating campaign document:', error);
    }
  };

  const createTransfer = async (salesAccountId, salesCommission) => {
    try {
      const response = await fetch('https://us-central1-streamwave-a18b7.cloudfunctions.net/scheduleTransfer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sales_account_id: salesAccountId, sales_commission: parseFloat(salesCommission) }),
      });
  
      if (response.ok) {
        const data = await response.json();
        //console.log('Transfer created:', data.transfer);
        updateCampaignDocument()
        // Handle the response or show success message to the user
      } else {
        throw new Error('Failed to create transfer');
      }
      //Update payment status___in FB doc

    } catch (error) {
      console.error('Error creating transfer:', error);
      // Handle the error or show error message to the user
    }
  };
  

  return (
    <div className="thank-you-page">
      <img src={Streamwave} alt="Streamwave.io Logo" className="logo" />
      <h1 className="heading">Thank you for advertising with Streamwave</h1>
      <p className="description">
        Kick back and relax! Our team is processing your campaign. You will receive an email notification once your campaign is
        live, along with subsequent emails containing ad performance data. Thank you for choosing Streamwave.io as your advertising
        partner! Contact us if you need any assistance or have further inquiries. <a>support@streamwave.io</a>
      </p>
      <div style={{ position: 'absolute', bottom: '0px', pointerEvents: 'none', width: '100vw' }}>
        <LottieAnimation animationData={confetti} height={'auto'} width={'100%'}></LottieAnimation>
      </div>
    </div>
  );
};

export default ThankYouPage;
