// ChatComponent.jsx
import React, { useState } from 'react';
import { FaPlay, FaArrowLeft, FaRegQuestionCircle } from 'react-icons/fa/index.esm.js';
import { FaSliders } from "react-icons/fa6/index.esm.js";
import { IoIosClose } from "react-icons/io/index.esm.js";
import styles from './audioEditor.module.scss'; // Import the CSS module
import { MdAudiotrack } from "react-icons/md/index.esm.js";

import AudioFilters from './AudioFilters/AudioFilters.js';
import voiceOptions from './voiceOptions.json'
const AudioEditor = (props) => {
    const [genState, setGenState] = useState('Waiting')
    const [audioUrl, setAudioUrl] = useState("");
    const [showSettings, setShowSettings] = useState(false);
    const [speedValue, setSpeedValue] = useState(1);
    const [varienceValue, setVarienceValue] = useState(.1);
    const [styleGuidance, setStyleGuidence] = useState(.1);
    const [selectedVoice, setSelectedVoice] = useState({
        "id": "s3://voice-cloning-zero-shot/1e64a221-3b48-48bb-bf5e-f9f656275979/jeff-gonzales/manifest.json",
        "name": "Jeff Gonzales",
        "type": "instant",
        "gender": "male",
        "voice_engine": "PlayHT2.0"
    });
    const [audioPrompt, setAudioPrompt] = useState(props.prompt.content[0].text.value)
    const handlePlayButtonClick = () => {
        // Add logic to play or pause audio here
    };



    const handleSpeedChange = (e) => {
        setSpeedValue(parseFloat(e.target.value));
    };

    const handleVarienceChange = (e) => {
        setVarienceValue(parseFloat(e.target.value));
    };

    const handleStyleGuidenceChange = (e) => {
        setStyleGuidence(parseFloat(e.target.value));
    };

    const getSelectedVoice = (voice) => {
        setSelectedVoice(voice)
    }

    const handleGenerateAudio = async () => {
        try {
            setGenState("Generating")
            const response = await fetch(`${process.env.REACT_APP_AD_VOICE_BACKEND_URL}/api/generateaudio`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prompt: audioPrompt,
                    voiceEngine: selectedVoice.voice_engine,
                    voiceId: selectedVoice.id,
                    temperature: varienceValue,
                    styleGuidance: styleGuidance
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }

            const audio = await response.json();
            console.log(audio)
            setGenState("Complete")
            setAudioUrl(audio.audioUrl)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }
    const handlePromptChange = (e) => {
        setAudioPrompt(e.target.value)
    }
    return (
        <div className={styles.chatComponent}>
            {/* Header with Back Icon */}
            <div className={styles.header}>
                <div onClick={props.returnToChat}>
                    <FaArrowLeft className={styles.backIcon} />
                </div>
                <h3>Audio Editor</h3>
            </div>
            <AudioFilters voiceData={voiceOptions} getSelectedVoice={getSelectedVoice}></AudioFilters>
            <div style={{ width: '100%', boxSizing: 'border-box', padding: '16px', overflow: 'hidden' }}>
                <textarea className={styles.textarea} onChange={handlePromptChange} placeholder="Type your audio script.." value={audioPrompt} />
            </div>

            {genState === "Waiting" ?
                <div className={styles.genBtnContainer}>
                    <div className={styles.generateAudioBtn} onClick={handleGenerateAudio}>
                        <MdAudiotrack style={{ color: 'white' }} />
                        <div>Generate Voiceover</div>
                    </div>
                    <div className={styles.settingsBtn} onClick={() => { setShowSettings(true) }}>
                        <FaSliders />
                    </div>
                </div> : null}
            {genState === "Generating" ?
                <div className={styles.genBtnContainer}>
                    <div className={styles.generateAudioBtn} style={{ backgroundColor: '#eda808' }}>
                        {/* <MdAudiotrack style={{ color: 'white' }} /> */}
                        <div>Generating...One Moment</div>
                    </div>
                    <div className={styles.settingsBtnGen} onClick={() => { setShowSettings(true) }}>
                        <FaSliders />
                    </div>
                </div> : null
            }
            {genState === "Complete" ?
                <div className={styles.audioContainer}>
                    <audio controls style={{marginBottom:'16px'}}>
                        <source src={audioUrl} type="audio/mpeg" />
                    </audio>
                    <div className={styles.genBtnContainer}>
                        <div className={styles.generateAudioBtn} onClick={handleGenerateAudio}>
                            <MdAudiotrack style={{ color: 'white' }} />
                            <div>Regenerate Voiceover</div>
                        </div>
                        <div className={styles.settingsBtn} onClick={() => { setShowSettings(true) }}>
                            <FaSliders />
                        </div>
                    </div>
                </div> : null}
            {showSettings ?
                <div className={styles.advancedSettings}>
                    <div className={styles.doubleArrows}>
                        <FaSliders style={{ fontSize: '18px', marginRight: '4px' }} />
                        <div>Voice Settings</div>
                    </div>
                    <div className={styles.closeBtn} onClick={() => { setShowSettings(false) }}>
                        <IoIosClose style={{ fontSize: '18px' }} />
                    </div>
                    <div className={styles.settingsWrapper}>
                        {/* <div>
                            <div className={styles.sliderLabels}>
                                <span>Speed</span>
                                <FaRegQuestionCircle />
                            </div>
                            <input
                                type="range"
                                min=".7"
                                max="2.2"
                                step="0.1"  // Adjust the step as needed
                                value={speedValue}
                                onChange={handleSpeedChange}
                                className={styles.sliderInput}
                            />
                        </div> */}
                        <div>
                            <div className={styles.sliderLabels}>
                                <span>Varience</span>
                                <FaRegQuestionCircle />
                            </div>
                            <input
                                type="range"
                                min=".1"
                                max="2"
                                step="0.1"  // Adjust the step as needed
                                value={varienceValue}
                                onChange={handleVarienceChange}
                                className={styles.sliderInput}
                            />
                        </div>
                        <div>
                            <div className={styles.sliderLabels}>
                                <span>Style Guidence</span>
                                <FaRegQuestionCircle />
                            </div>
                            <input
                                type="range"
                                min=".1"
                                max="30"
                                step="0.1"  // Adjust the step as needed
                                value={styleGuidance}
                                onChange={handleStyleGuidenceChange}
                                className={styles.sliderInput}
                            />
                        </div>
                    </div>
                </div> : null
            }
        </div>
    );
};

export default AudioEditor;
