import React, { useEffect, useState } from 'react';
import './viewcampaign.css';
import { db } from '../firebase/firebaseApp';
import { doc, getDoc } from 'firebase/firestore';
import Logo from '../Images/Streamwave_Blk.png'
const AdCampaign = () => {
  const [data, setData] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  useEffect(() => {
    const getCampaign = async (id) => {
      try {
        const docRef = doc(db, 'campaigns', id);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          console.log(data)
          setData(data);
        } else {
          console.log('Document not found');
        }
      } catch (error) {
        console.error('Error fetching campaign:', error);
      }
    };

    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    if (id) {
      getCampaign(id);
    }
  }, []);

  const checkPw = (e) => {
    if (e.target.value === "evolution") {
      setShowDetails(true)
    } else {
      setShowDetails(false)
    }
  }


  return (
    <>
      {!showDetails ? (
        <div className="pw-ptrotected-wrapper">
          <img src={Logo} alt="streamwave.io" style={{ position: 'relative', display: 'block', maxWidth: '350px', width: '70%', textAlign: 'center', margin: 'auto' }}></img>
          <div className="enter-pwd-label">Enter Password</div>
          <input type="password" onChange={(e) => { checkPw(e) }}></input>
        </div>
      ) : (

        <div>
          {data && Object.keys(data).length > 0 ? (
            <div className="ad-campaign">
              <img src={Logo} alt="streamwave.io" style={{ position: 'relative', display: 'block', maxWidth: '350px', width: '70%', textAlign: 'center', margin: 'auto' }}></img>
              <h2 style={{ textAlign: 'center', fontSize: '22px' }}>Deployment Details</h2>
              <section className="section-container">
                <div className="view-h1">Budget Breakdown</div>
                <div className="data-group">
                  <div className="view-label-heading">Gross Spend</div>
                  <div className="view-label-information">{"$" + data.gross_spend}</div>
                </div>
                <div className="data-group">
                  <div className="view-label-heading">Processing Fees (2.9% + 30 cents) - Stripe</div>
                  <div className="view-label-information">{"$" + data.processing_fees}</div>
                </div>
                <div className="data-group">
                    <div className="view-label-heading">{`Total Commission (23% of spend)`}</div>
                    <div className="view-label-information">{"$" + data.streamwave_net}</div>
                  </div>
                {data.referral_commission ?
                  <div className="data-group">
                    <div className="view-label-heading">Referral Commission (Steamwave Bearing)</div>
                    <div className="view-label-information">{"$" + data.referral_commission}</div>
                  </div> : null
                }
                 {data.partner_commission ?
                  <div className="data-group">
                    <div className="view-label-heading">{`Partner Commission - ${data.partner}`}</div>
                    <div className="view-label-information">{"$" + data.partner_commission}</div>
                  </div> : null
                }

                <div className="data-group">
                  <div className="view-label-heading">Streamwave Commission</div>
                  <div className="view-label-information">{"$" + data.streamwave_commission}</div>
                </div>
                <div className="data-group">
                  <div className="view-label-heading">Net Spend (Evolution Media)</div>

                  <div className="view-label-information" style={{ fontWeight: 800, color: 'var(--primary-color)', fontSize: '30px' }}>{"$" + data.net_spend}</div>

                </div>
              </section>
              <section className="section-container">
                <div className="view-h1">Campaign Details</div>
                <div className="data-group">
                  <div className="view-label-heading">Campaign ID</div>
                  <div className="view-label-information">{data.documentId}</div>
                </div>
                <div className="data-group">
                  <div className="view-label-heading">Campaign Name</div>
                  <div className="view-label-information">{data.campaign.campaign.campaign_name}</div>
                </div>
                <div className="data-group">
                  <div className="view-label-heading">Company Logo</div>
                  <a className="view-label-information" href={data.campaign.campaign.company_logo} target="_blank">{"View Logo"}</a>
                </div>
                {data.campaign.campaign.campagin_start_date ? 
                 <div className="data-group">
                 <div className="view-label-heading">Start Date</div>
                 <div className="view-label-information">{data.campaign.campaign.campagin_start_date}</div>
                 <div className="view-label-heading">End Date</div>
                 <div className="view-label-information">{data.campaign.campaign.campagin_end_date}</div>
               </div>
                 :<div className="data-group">
                 <div className="view-label-heading">Flight Dates</div>
                 <div className="view-label-information">{data.campaign.campaign.campagin_flight_dates + " (" + data.campaign.campaign.campagin_duration + " days)"}</div>
               </div>
                }
               
                <div className="data-group">
                  <div className="view-label-heading">Landing Page</div>
                  <div className="view-label-information">{data.campaign.campaign.campaign_landing_page}</div>
                </div>
                <div className="data-group">
                  <div className="view-label-heading">Company Name</div>
                  <div className="view-label-information">{data.campaign.campaign.company_name}</div>
                </div>
                <div className="data-group">
                  <div className="view-label-heading">Company Website</div>
                  <div className="view-label-information">{data.campaign.campaign.company_website}</div>
                </div>
              </section>
              <section className="section-container">
                <div className="view-h1">Targeting</div>
                <div className="data-group">
                  <div className="view-label-heading">Industry</div>
                  {data.campaign.targeting.industries && data.campaign.targeting.industries.length > 0 ? (
                    <div>
                      {data.campaign.targeting.industries.map((option) => (
                        <div key={option.value} className="view-audience-pill">{option.label}</div>
                      ))}
                    </div>
                  ) : (
                    <div>No Industries Selected</div>
                  )}
                </div>


                <div className="data-group">
                  <div className="view-label-heading">Audiences</div>
                  {data.campaign.targeting.audiences?.length > 0 ? (
                    <div>
                      {data.campaign.targeting.audiences.map((option) => (
                        <div className="view-audience-pill">{option}</div>
                      ))}
                    </div>
                  ) : <div>No Audiences Selected</div>}
                </div>
                <div className="data-group">
                  <div className="view-label-heading">Marketing Objective</div>
                  <div className="view-label-information">{data.campaign.targeting.objective}</div>
                </div>
              </section>
              <section className="section-container">
                <div className="view-h1">Creative</div>
                <div className="data-group">
                  <div className="view-label-heading">Type</div>
                  <div className="view-label-information">{data.campaign.creative.type}</div>
                </div>
                <div className="data-group">
                  <div className="view-label-heading">Creative Asset</div>
                  {/* {typeof data.campaign.creative === 'object' ? 
                    <a className="view-label-information" href={data.campaign.creative.url} style={{ textDecoration: 'underline' }}>{"Download Creative"}</a>:null
                  } */}
                  {data.campaign.creative.length>0 ? 
                    data.campaign.creative.map((creative)=>{
                      return(
                        <a className="view-label-information" href={creative.url} style={{ textDecoration: 'underline' }}>{"Download Creative"}</a>
                      )
                    }):<a className="view-label-information" href={data.campaign.creative.url} style={{ textDecoration: 'underline' }}>{"Download Creative"}</a>
                    
                  }
                 
                </div>
              </section>
              <section className="section-container">
                <div className="view-h1">Contact Details</div>
                <div className="data-group">
                  <div className="view-label-heading">First Name</div>
                  <div className="view-label-information">{data.campaign.contact.first_name}</div>
                </div>
                <div className="data-group">
                  <div className="view-label-heading">Last Name</div>
                  <div className="view-label-information">{data.campaign.contact.last_name}</div>
                </div>
                <div className="data-group">
                  <div className="view-label-heading">Email</div>
                  <div className="view-label-information">{data.campaign.contact.email}</div>
                </div>
                <div className="data-group">
                  <div className="view-label-heading">Phone</div>
                  <div className="view-label-information">{data.campaign.contact.phone}</div>
                </div>
              </section>
            </div>
          ) : null}
        </div>
      )}

    </>
  );
};

export default AdCampaign;
