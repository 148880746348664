import React, { useEffect, useState } from 'react';
import './partners.css';
import { db } from '../firebase/firebaseApp';
import { doc, getDocs, collection } from 'firebase/firestore';
import Logo from '../Images/Streamwave_Blk.png';
import DateRangeModal from '../Create/DatePicker/DateRange_Accounting.js';
const AdCampaign = () => {
  const [data, setData] = useState({});
  const [allCampaigns, setAllCampaigns] = useState([])
  const [totalCommission, setTotalCommission] = useState(null);
  const [numCampaigns, setNumCampaigns] = useState(null);
  const [timeWindow, setTimeWindow] = useState({})
  const [aetPayment, setAETPayment] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  useEffect(() => {
    getCampaigns();
  }, [showDetails]);

  useEffect(() => {
    //calculate the total owed
    let total = 0
    for (let i = 0; i < data.length; i++) {
      total = total + data[i].streamwave_commission
    }
    setTotalCommission(total.toFixed(2))
  }, [data]);

  useEffect(() => {
 
    let campagins = [...allCampaigns]
    const startDate = new Date(timeWindow.startDate);
    const endDate = new Date(timeWindow.endDate);

    // Filter campaigns within the date range
    const filteredCampaigns = campagins.filter((item) => {
      const submissionDate = new Date(item.submissionDate.seconds * 1000 + item.submissionDate.nanoseconds / 1000000);
      return submissionDate >= startDate && submissionDate <= endDate;
    });

    // Calculate the total commission for the filtered campaigns
    const newTotal = filteredCampaigns.reduce((total, campaign) => total + campaign.streamwave_commission, 0);
    console.log('New Total Commission:', allCampaigns);

    // Update the data state with filtered campaigns
    setData(filteredCampaigns);

    // Update the total commission state
    setTotalCommission(newTotal);
    setNumCampaigns(filteredCampaigns.length)
    // Perform additional operations with the filteredCampaigns if needed
  }, [timeWindow]);



  const getCampaigns = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'campaigns'));
      const campaigns = [];

      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          const data = doc.data();
          campaigns.push(data);

        } else {
          console.log('Document not found');
        }
      });
      setAllCampaigns(campaigns)
      setData(campaigns)
      //console.log(campaigns)
      // Do something with the campaigns array, e.g., set it to state or return it

    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const checkPw = (e) => {
    if (e.target.value === "eamer") {
      setShowDetails(true)
    } else {
      setShowDetails(false)
    }
  }
  function convertTimestampToMMDDYY(timestamp) {
    const dateObject = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const year = dateObject.getFullYear().toString().slice(2);
    return `${month}/${day}/${year}`;
  }

  const updateDates = (dates) => {
    
    if (allCampaigns.length>0) {
      setTimeWindow(dates)
    }
    
  }

  return (
    <>
      {!showDetails ? (
        <div className="pw-ptrotected-wrapper">
          <img src={Logo} alt="streamwave.io" style={{ position: 'relative', display: 'block', maxWidth: '350px', width: '70%', textAlign: 'center', margin: 'auto' }}></img>
          <div className="enter-pwd-label">Enter Password</div>
          <input type="password" onChange={(e) => { checkPw(e) }}></input>
        </div>
      ) : (

        <div>
          {data ? (
            <div className="ad-campaign">
              <img src={Logo} alt="streamwave.io" style={{ position: 'relative', display: 'block', maxWidth: '350px', width: '70%', textAlign: 'center', margin: 'auto' }}></img>
              <h2 style={{ textAlign: 'center', fontSize: '22px' }}>Streamwave Campaigns</h2>
              <div style={{ display: 'flex', gap: '40px', margin:'auto', width:"fit-content" }}>
                <div>
                  <div>{"Total AET Commission"}</div>
                  <h1 style={{ marginBottom: '0px', textAlign:'center' }}>{"$" + totalCommission}</h1>
                </div>
                <div>
                  <div>{"Total Campaigns"}</div>
                  <h1 style={{ marginBottom: '0px', textAlign:'center' }}>{numCampaigns}</h1>
                </div>
              </div>
            
              <DateRangeModal updateDates={updateDates} />
              
              
              {data.length > 0 ?
                <table className="pa-table">
                  <thead>
                    <tr>
                      <th className="pa-th">Submission Date</th>
                      <th className="pa-th">Campaign Id</th>
                      <th className="pa-th">SW Commission</th>
                      <th className="pa-th">Payment Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td className="pa-td">{convertTimestampToMMDDYY(item.submissionDate)}</td>
                        <td className="pa-td"><a target="_blank" href={`https://streamwave.io/campaigns/?id=${item.documentId}`}>{item.documentId}</a></td>
                        <td className="pa-td">{"$" + item.streamwave_commission.toFixed(2)}</td>
                        <td className="pa-td">{item.payment_status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table> : <div style={{textAlign:'center', marginTop:'20px'}}>No Campaign Data Found for this Time Window</div>
              }
              <div style={{height:'300px'}}></div>
            </div>
          ) : null}
        </div>
      )}

    </>
  );
};

export default AdCampaign;
