let options = [{
    label: 'Agriculture', value: 'agriculture', subindustries: [{ label: 'Agricultural equipment', value: 'https://docs.google.com/spreadsheets/d/1oBTtpPK5kEi1yAEkxHUO1TewC2FlCzwp3GZF92_Mct4/edit#gid=0' }, { label: 'Livestock', value: 'https://docs.google.com/spreadsheets/d/11UMQ3OaKpkwtbR-bG1TDBrOlBcWivcaQI74hjkoZrjw/edit#gid=0' }]
  },
  {
    label: 'Alcohol (beer, wine & spirits)',
    value: 'alcohol',
    subindustries: [
      { label: 'Beer production', value: 'https://docs.google.com/spreadsheets/d/1zFIYeh1FJYKW_mTQ-hXwv_s-eZpz7ccJpu4nSGs_OQc/edit#gid=0' },
      { label: 'Wine production', value: 'https://docs.google.com/spreadsheets/d/1lgayKnltRnOWOUKYcQ97Y45B3Pm5T9Mxaj0YBkERM4M/edit#gid=0' },
      { label: 'Spirits production', value: 'https://docs.google.com/spreadsheets/d/1thaMe2i430ssLV58FLPnqv-x28U9dLly1V0xWDCleQ4/edit#gid=0' },
      { label: 'Retail sales', value: 'https://docs.google.com/spreadsheets/d/1NvYG9lGXgGz8AkRpYCqDnG_XHbYLsOKAtkzwz1sLkm0/edit#gid=0' },
      { label: 'Distribution', value: 'https://docs.google.com/spreadsheets/d/14kv7JQnQxZovoekamU929xpEtLJjLkmi74p9rgK6WKc/edit#gid=0' },
      { label: 'Independent brewery', value: 'https://docs.google.com/spreadsheets/d/1I00RaADRkw1w2QJlpY0NeHP_apWwirk2AxLzHZMeV5Q/edit#gid=0' }
    ]
  },
  {
    label: 'Architecture',
    value: 'architecture',
    subindustries: [
      { label: 'Residential', value: 'https://docs.google.com/spreadsheets/d/1Dg6KsfBFjoLI9abjIQfP82zohCwVn6X2ydFBTF1B2dQ/edit#gid=0' },
      { label: 'Commercial', value: 'https://docs.google.com/spreadsheets/d/1-Fe8enkdR4HkPOSEMUWgCsm8vAPAKBCLqnVtmk44nPw/edit#gid=0' },
      { label: 'Interior', value: 'https://docs.google.com/spreadsheets/d/1wr5BMyBs5xSLsALjTkJxB67X1ZKaCUyRT3Jj8fA3etc/edit#gid=0' },
      { label: 'Landscape', value: 'https://docs.google.com/spreadsheets/d/1m39o-BVUY5AFH4FK6SICtF1WXCxoa5pAHH45y_k0ibw/edit#gid=0' },
      { label: 'Urban planning', value: 'https://docs.google.com/spreadsheets/d/1BcX1ISkpKeZi66rEBiyVBQCUaQ08Do9Vgsw45l5UZF0/edit#gid=0' },
      { label: 'Sustainable design', value: 'https://docs.google.com/spreadsheets/d/1Q_v0vlGH45VEegOC4D65jqXQlh7_rKVegLmU8obQSf4/edit#gid=0' },
      { label: 'Preservation', value: 'https://docs.google.com/spreadsheets/d/1G7JAWibdT93oDiXE-lQuFcQRrCQWEYUHAfW-nHCs_Hg/edit#gid=0' }
    ]
  },
  {
    label: 'Arts and culture',
    value: 'arts-and-culture',
    subindustries: [
      { label: 'Museums & galleries', value: 'https://docs.google.com/spreadsheets/d/1P4eL3z34euCJxavj00XVIP3qGJneGHBdmiOYmXoATNU/edit#gid=0' },
      { label: 'Performing arts', value: 'https://docs.google.com/spreadsheets/d/1GMmOyKSOXr8ypeHYUAbVq8-4nFVaiaVwlnx7B-DADKk/edit#gid=0' },
      { label: 'Film Entertainment', value: 'https://docs.google.com/spreadsheets/d/1NcJbX256beCD-wimZkf0JMJ7biu28UzS2qxzn4pukGE/edit#gid=0' }
    ]
  },
  {
    label: 'Automotive',
    value: 'automotive',
    subindustries: [
      { label: 'Repair and maintenance', value: 'https://docs.google.com/spreadsheets/d/1iSVjx8FfM3jpwxamNuHPMzo83QULWL1VRz4Zd1yXw3U/edit#gid=0' },
      { label: 'Dealership (new or used)', value: 'https://docs.google.com/spreadsheets/d/13AAGDqQ6UgtJtqQUU1bViVKp54Oo22FTBgucuYEaAV0/edit#gid=0' },
      { label: 'OEM', value: 'https://docs.google.com/spreadsheets/d/1q4xclUCsrMq9KJVUKSkiDQGxL-txRaVniVzh7YvywhM/edit#gid=0' },
      { label: 'Parts & accessories', value: 'https://docs.google.com/spreadsheets/d/1NieSEQ_Wfkl6_sKuVKq4MT9-oLD9d7R9u9DTyJWehIw/edit#gid=0' }
    ]
  },
  {
    label: 'Beauty and cosmetics',
    value: 'beauty-and-cosmetics',
    subindustries: [
      { label: 'Skincare', value: 'https://docs.google.com/spreadsheets/d/16CoNN02rJ263lBxDcDvq6KLAI5GUrhn50rPP8a0XeQY/edit#gid=0' },
      { label: 'Makeup', value: 'https://docs.google.com/spreadsheets/d/1AgWKcS_qV3IMGIwz0Z--xSe972gSHBr135UWcqiBr3I/edit#gid=0' },
      { label: 'Haircare', value: 'https://docs.google.com/spreadsheets/d/16q-zfocOlRLdAzH8OyAGNi9ae86IQKKfjq2I2zUCGOM/edit#gid=0' },
      { label: 'Fragrances', value: 'https://docs.google.com/spreadsheets/d/1lYjEfSCkLQw7W0r6AFR33Dn5IOZZWoEOtvcAI0yAQaA/edit#gid=0' },
      { label: 'Cosmetic Services', value: 'https://docs.google.com/spreadsheets/d/1OCpTzTFeoYl1_X0HPmyMD57n-8gGgzj7zRaI_79kEfw/edit#gid=0' },
      { label: 'Cosmetic Retail', value: 'https://docs.google.com/spreadsheets/d/1TFcs2XthGxO4DdSlDUu4S3mElJ5oockqx0CaHuXXoCE/edit#gid=0' }
    ]
  },
  {
    label: 'Construction',
    value: 'construction',
    subindustries: [
      { label: 'Residential', value: 'https://docs.google.com/spreadsheets/d/1g48gy0VGQsUNKFpfkDnmAPnz3d7A5m4-C4uVqPJOJiU/edit#gid=0' },
      { label: 'Commercial', value: 'https://docs.google.com/spreadsheets/d/1R4MeJGVlRxNHtlcLpYK0-tJiXZzxJYMrWnEj0-cnPP0/edit#gid=0' },
      { label: 'Infrastructure', value: 'https://docs.google.com/spreadsheets/d/1p5fWHdXkShdugsh77nXjOj1eA3EdL6JOVrPrlWuJnlg/edit#gid=0' },
      { label: 'Renovation & remodel', value: 'https://docs.google.com/spreadsheets/d/1XsPpIaaK_EDZiQWfYVoenwEaOSmQH2MZy7XVLcdOD-A/edit#gid=0' },
      { label: 'Specialty ', value: 'https://docs.google.com/spreadsheets/d/1aRadiE1ask7yoZP-gu8cC88PWqhPHryZ6QLtHhnHXck/edit#gid=0' },
      { label: 'Equipment & Materials', value: 'https://docs.google.com/spreadsheets/d/19Syy_n_LavEdpECkq_U58PHnS1cZrSZJIezfzxKjasU/edit#gid=0' }
    ]
  },
  {
    label: 'Construction equipment',
    value: 'construction-equipment',
    subindustries: [
      { label: 'Earthmoving', value: 'https://docs.google.com/spreadsheets/d/1g4-hkPEhGrrfw70vif-myFnw9nc-2Q_ky8a_Mci4fbU/edit#gid=0' },
      { label: 'Material handling', value: 'https://docs.google.com/spreadsheets/d/1MmtABRh5zznmgWeEspr4qHDoomAJH2yxlGbIJV2VWV0/edit#gid=0' },
      { label: 'Vehicles', value: 'https://docs.google.com/spreadsheets/d/1bcd0ETdxR_5B3Rs1pHMD-5r_4z1z47EUM8ZiSnLNyRo/edit#gid=0' },
      { label: 'Tools', value: 'https://docs.google.com/spreadsheets/d/168sEzRI2KbOpb4rexMdTQyBPR5mQN-JTudPBtPD81LQ/edit#gid=0' },
      { label: 'Technology', value: 'https://docs.google.com/spreadsheets/d/1F3kP-iUv2O2kh_X53ne73W3E5dD4iXDDXcUvU6j5IQA/edit#gid=0' },
      { label: 'Rental & leasing', value: 'https://docs.google.com/spreadsheets/d/15urkw_zbqb2DKWAPfZcnDNd54of1SH9klp7PC90vdCg/edit#gid=0' },
      { label: 'Parts & service', value: 'https://docs.google.com/spreadsheets/d/1Z2-yo4saGlyiKi9COwYNHBlUCPLk3QfgrsMwkRVipEs/edit#gid=0' }
    ]
  },
  {
    label: 'Consumer electronics',
    value: 'consumer-electronics',
    subindustries: [
      { label: 'Repair and maintenance', value: 'https://docs.google.com/spreadsheets/d/1iC7IJOZ5Xa0FXHJJsW18uVLk5uWnI4RO7y28SvovO88/edit#gid=0' },
      { label: 'Home Appliances', value: 'https://docs.google.com/spreadsheets/d/1vPO-VuakQpx61aqqBye1RX8bJCmulcxc3rxOn_vMJQA/edit#gid=0' }
    ]
  },
  {
    label: 'Creative Services',
    value: 'creative-services',
    subindustries: [
      { label: 'Photography', value: 'https://docs.google.com/spreadsheets/d/1cARVAFTK-i8Kr4JS_asO0wXZ9xJ2GLRWRlcDCsxtghg/edit#gid=0' },
      { label: 'Video Production', value: 'https://docs.google.com/spreadsheets/d/1MmOMBBLkOOujGPDtEMmihTOioPxZkec_fJlU9kDD7BI/edit#gid=0' },
      { label: 'Graphic design', value: 'https://docs.google.com/spreadsheets/d/1642Y5xXpi8YGVufpKg72w1C27vYBlVlZwp8daLeb8Lo/edit#gid=0' },
      { label: 'Web & app development', value: 'https://docs.google.com/spreadsheets/d/1RvRZPd3YWY4M9xLbtwYmNf4TB-KTisC6FWPGVXUf3aU/edit#gid=0' },
      { label: 'Music & sound production', value: 'https://docs.google.com/spreadsheets/d/1nSZnWaTOzDZqigEZ-DdcR-CdGn8aOsXW4J_QVubpmFY/edit#gid=0' },
      { label: 'Digital marketing', value: 'https://docs.google.com/spreadsheets/d/1Xg7pNe1LDEFnOIiuktWYgewUhB1JmKJD1M0c9VxU1xI/edit#gid=0' }
    ]
  },
  {
    label: 'E-commerce',
    value: 'e-commerce',
    subindustries: [
      { label: 'Online retail', value: 'https://docs.google.com/spreadsheets/d/1VCEEEE9tw4i1FpcrRLaKtbOCJS9FcC7_ssAA7PcgPsg/edit#gid=0' },
      { label: 'Online marketplace', value: 'https://docs.google.com/spreadsheets/d/15J-VUlLr_Vjx-FSySdLWEa6eTBuV92vhkotC_hie3xA/edit#gid=0' },
      { label: 'Payment processing', value: 'https://docs.google.com/spreadsheets/d/1VCEEEE9tw4i1FpcrRLaKtbOCJS9FcC7_ssAA7PcgPsg/edit#gid=0' },
    ]
  },
  {
    label: 'Education',
    value: 'education',
    subindustries: [
      { label: 'EdTech', value: 'https://docs.google.com/spreadsheets/d/1FjNq8qD1yEQuNtaeYlEbBdpXWKqM5t5Nj4izz9LlHzg/edit#gid=0' },
      { label: 'E-learning', value: 'https://docs.google.com/spreadsheets/d/1YuVVtFJNkxrcrZCnIy8LDOjE67YhrCpzZkvapMmZiBI/edit#gid=0' },
      { label: 'Higher education', value: 'https://docs.google.com/spreadsheets/d/169rH1WQ5x0z4vA70oIz1KLTYJUsqsfvkSOS7UNqhOEI/edit#gid=0' },
      { label: 'Language learning', value: 'https://docs.google.com/spreadsheets/d/1nML6O5k4WxU3_lZr5B7VPAY8qyL4i0CHdXWv2koy0j4/edit#gid=0' },
      { label: 'Education publishing', value: 'https://docs.google.com/spreadsheets/d/1CSrdrw1dF0nbZLFs5Oj9dD24knCqpIUcVgpNr2ZfEpE/edit#gid=0' }
    ]
  },
  {
    label: 'Energy',
    value: 'energy',
    subindustries: [
      { label: 'Solar', value: 'https://docs.google.com/spreadsheets/d/1qW8dE90HbM3aKTDPLnp9tOLMes2R9KuE89EWgQLEEc8/edit#gid=0' },
      { label: 'Oil & gas', value: 'https://docs.google.com/spreadsheets/d/1rMNLXbB_uKf8Sc3je6F8AQsvFr0olrkgT0rsyStydWo/edit#gid=0' },
      { label: 'Renewable', value: 'https://docs.google.com/spreadsheets/d/1ppxLdCznKW9juBHgm3-Vs9rehwK1EefU5Camu_MUuC8/edit#gid=0' },
      { label: 'Trading & marketing', value: 'https://docs.google.com/spreadsheets/d/1RuofaDnJhlDwrOk40y7gIUqDVgDlbSjaHB96gZihOzA/edit#gid=0' },
      { label: 'Consulting', value: 'https://docs.google.com/spreadsheets/d/163KYu3pdIAylMWO4g2lTBMynH2Q8AJxACaHnu_jRM9o/edit#gid=0' },
      { label: 'Storage', value: 'https://docs.google.com/spreadsheets/d/1y1lwabWQjc9T2Ad4VaLYgUj3EswrQSQp-3HDMFndxfs/edit#gid=0' }
    ]
  },
  {
    label: 'Entertainment and media',
    value: 'entertainment-and-media',
    subindustries: [
      { label: 'Film production', value: 'https://docs.google.com/spreadsheets/d/1RjRntMZ0JLuKZS1DDIwZnhsHhFFYuY3oIDk5nuEcT9M/edit#gid=0' },
      { label: 'Music production', value: 'https://docs.google.com/spreadsheets/d/1v0RI1p2Emm1OUkt2QmjGFwjKyhdOHRMOveFPTE__ZyA/edit#gid=0' },
      { label: 'Publishing', value: 'https://docs.google.com/spreadsheets/d/1k_g0bS3iwfzTb7-CIJyfakbSuzCM2YHSd4WFYRNiNjs/edit#gid=0' },
      { label: 'Live events', value: 'https://docs.google.com/spreadsheets/d/1ZtFF8TFoDmQRcDEgMXgj4o1QdZsocqfuqWgFoDTy0vs/edit#gid=0' }]
  },
  {
    label: 'Environmental',
    value: 'environmental',
    subindustries: [
      { label: 'Renewables', value: 'https://docs.google.com/spreadsheets/d/1jYqi13cJ1lHQjc1o6kSiHBLGjWmJV5Xk2jHOIBQVndY/edit#gid=0' },
      { label: 'Waste Management', value: 'https://docs.google.com/spreadsheets/d/1KjMbmNnAOKj9eIH8hefkUVOnAWGOWsxg0y_LWJ_xytQ/edit#gid=0' },
      { label: 'Water management', value: 'https://docs.google.com/spreadsheets/d/1sgRCdhVoY0LvNA1Ti5_rfpSOQflOu7eYZBbNVZIw6vA/edit#gid=0' },
      { label: 'Consulting', value: 'https://docs.google.com/spreadsheets/d/15SjqORN9AOwENjCP6mUD_S_u5fi0B2vB3Or7MHF8YdQ/edit#gid=0' },
      { label: 'Environmental engineering', value: 'https://docs.google.com/spreadsheets/d/1-T5ISRAbBTxO1Th7YvvHbDNarJ8Z4G4ygCKgUcs2rqI/edit#gid=0' },
      { label: 'Sustainable products', value: 'https://docs.google.com/spreadsheets/d/1pnMRuQ1yMYsPsjaoO0yVPaUhevi98T-W1UpuSnMnE64/edit#gid=0' }
    ]
  },
  {
    label: 'Equipment rental',
    value: 'equipment-rental',
    subindustries: [
      { label: 'Construction equipment', value: 'https://docs.google.com/spreadsheets/d/1HqcW0P6ikyb1YOZN9vagQaPWAsL0dvrf9pOdwEI13YQ/edit#gid=0' },
      { label: 'Event equipment', value: 'https://docs.google.com/spreadsheets/d/1mPC_RWEWRzgktbFcFKNptvQ11rvI-C9BFvT7vcgRliU/edit#gid=0' },
      { label: 'Office equipment', value: 'https://docs.google.com/spreadsheets/d/1qrUPJ03e970G8iNjh81zkPACBBNCpDNws--3Q_8YzFA/edit#gid=0' },
      { label: 'Audio-visual equipment', value: 'https://docs.google.com/spreadsheets/d/1tLmOCT49NGkoEOhv2EjOkftgPnuPOBhtl_rq0U5FbmU/edit#gid=0' },
      { label: 'Medical equipment', value: 'https://docs.google.com/spreadsheets/d/1tAZf0vzk094JewxdaABK1hn53gNizaY5h0qs0J_Sv_g/edit#gid=0' },
      { label: 'Party & entertainment Equipment', value: 'https://docs.google.com/spreadsheets/d/1I1SoQQuXYWewjHkWTPuLR_6b4ZeLZIYqiRBtzXcbjP4/edit#gid=0' }
    ]
  },
  {
    label: 'Event management',
    value: 'event-management',
    subindustries: [
      { label: 'Corporate events', value: 'https://docs.google.com/spreadsheets/d/15C2KNup9gslLaJt80AY1g579K_umVeQ8Onswap4_j7E/edit#gid=0' },
      { label: 'Festivals & fairs', value: 'https://docs.google.com/spreadsheets/d/1k2FeEgwH3fK1Q8NK1Uk7Z7nG9zeYZtU7PA6g9jk_oIg/edit#gid=0' },
      { label: 'Sports events', value: 'https://docs.google.com/spreadsheets/d/1LlrBIl1RpjJRVsTIr6jMyAhbmg4IHLhR152liJAIC5I/edit#gid=0' },
      { label: 'Cultural events', value: 'https://docs.google.com/spreadsheets/d/1zm48de2LJRu5HNwLHSxvcbR8lWzbwdkEzwUNbNhNf-c/edit#gid=0' },
      { label: 'Charity events', value: 'https://docs.google.com/spreadsheets/d/1wM1wSulu66_qBQ6aV9pCbG02fp3ySWYg34rcpWu80RY/edit#gid=0' },
      { label: 'Event production', value: 'https://docs.google.com/spreadsheets/d/1r1A5vppkzLrl0UfJeJfGm0--hnpwbGo4GcP9dMFWpWg/edit#gid=0' }
    ]
  },
  {
    label: 'Fashion',
    value: 'fashion',
    subindustries: [
      { label: 'Footwear', value: 'https://docs.google.com/spreadsheets/d/1N2eXBeJgY9L6tqWKdcED1L2a10mUhDoY3v6mtKfcsq8/edit#gid=0' },
      { label: 'Design & manufacturing', value: 'https://docs.google.com/spreadsheets/d/1qtW3EGfkwpkgF4OwPzTtvfXZxEB1uso3VjW1eKUwlzw/edit#gid=0' },
      { label: 'Retail & ecommerce', value: 'https://docs.google.com/spreadsheets/d/1ZKjd2Bi6cUCXgMDq6wMCqGga-ow6Pv3c8QfI70n5sr4/edit#gid=0' }
    ]
  },
  {
    label: 'Financial services',
    value: 'financial-services',
    subindustries: [
      { label: 'Banking', value: 'https://docs.google.com/spreadsheets/d/1-rxUQy66kRtcsPbucC7fe-fBFahZQ_o6bZYFtYJCB-4/edit#gid=0' },
      { label: 'Venture capital', value: 'https://docs.google.com/spreadsheets/d/1RHnBoCXdqxi7vd1Oxmqxfyi6-RBK7DDlmRDKcDP0JZM/edit#gid=0' },
      { label: 'Financial planning', value: 'https://docs.google.com/spreadsheets/d/1YUKwn04KTFseeMJWJBSVNRUPYqjyGDULFEOcU-rDdKo/edit#gid=0' },
      { label: 'Asset management', value: 'https://docs.google.com/spreadsheets/d/1pamsdI9NerRZIV5b0yc0yKG9S77V5gWIET3MsnoLXbw/edit#gid=0' },
      { label: 'Planning & advisory', value: 'https://docs.google.com/spreadsheets/d/15Oz5fACtGOwA60S1UX-w0l2NGb8ZXozcnY6wDBxTbMY/edit#gid=0' },
      { label: 'Payment processing', value: 'https://docs.google.com/spreadsheets/d/1DaTKa6GIUu6_pnuD35pyh651hjeeHWqX-z2vWcoNXc4/edit#gid=0' },
      { label: 'FinTech', value: 'https://docs.google.com/spreadsheets/d/1eIgam5hi0OeiGafpAGaiVmHbYBg00BvYJoUrVSkAmPg/edit#gid=0' },
      { label: 'Investment', value: 'https://docs.google.com/spreadsheets/d/1TWb4ko6hZAD6qk0nthIlvfSu0r7O2C5ZVd-DMtvx1k0/edit#gid=0' }
    ]
  },
  {
    label: 'Fitness',
    value: 'fitness',
    subindustries: [
      { label: 'Gym', value: 'https://docs.google.com/spreadsheets/d/1p2PSxkN44t_gbZQaqbetDPIEiruR_Hdi9YQF30Wgx8k/edit#gid=0' },
      { label: 'Personal training or coaching', value: 'https://docs.google.com/spreadsheets/d/1y0RQ56DmWnMhi5cI2xJNMflQEiaaP6tlFmwc8DI5Mhw/edit#gid=0' },
      { label: 'Equipment & apparel', value: 'https://docs.google.com/spreadsheets/d/1aSxLMarK68V8bIEQTUUefEA8PLHBJ3jwNjG-2r1vK0c/edit#gid=0' },
      { label: 'Yoga & pilates', value: 'https://docs.google.com/spreadsheets/d/12S0BdKBW7S-Mff3AXdS0tKIw3-Bs7_IAxPouRDVEpw0/edit#gid=0' },
      { label: 'Nutrition & supplements', value: 'https://docs.google.com/spreadsheets/d/1OBoL8Pl13rKvPck2Y5SkFuV1IC4mYE7sRn-o18ACjcU/edit#gid=0' }
    ]
  },
  {
    label: 'Food and beverage',
    value: 'food-and-beverage',
    subindustries: [
      { label: 'Restaurants', value: 'https://docs.google.com/spreadsheets/d/1fwyWpyMi78jsWrdWO0_O6ltrIj-bIR-zWjJY1h6cgzo/edit#gid=0' },
      { label: 'Bars', value: 'https://docs.google.com/spreadsheets/d/1iJeHUw_sgMUp6h5Yr-XwVN0H-P_LzQZU0vtLfsrmTbw/edit#gid=0' },
      { label: 'Catering', value: 'https://docs.google.com/spreadsheets/d/1plAxGVwo3srfLCeNdedWJsaOtItKO_QCBJZmWXODzhE/edit#gid=0' },
      { label: 'Manufacturing', value: 'https://docs.google.com/spreadsheets/d/1VscpiarS2fLpYdP_IFMESj6Mh3AExGAvLrWE8A3Uops/edit#gid=0' },
      { label: 'Packaging', value: 'https://docs.google.com/spreadsheets/d/1xBz6cxVGVajfYNDvOiTVfHR2ff6tBiYO3f7S3XcD28M/edit#gid=0' }
    ]
  },
  {
    label: 'Gaming',
    value: 'gaming',
    subindustries: [
      { label: 'Hardware', value: 'https://docs.google.com/spreadsheets/d/1Jp2ahLFYkcQull9JGK2PtDnFQMCTKuXPnRISbcWF5l0/edit#gid=0' },
      { label: 'Development', value: 'https://docs.google.com/spreadsheets/d/1qMybBqEgLmCjts4-izbLdkn7DGVc8fktdqL7CrIF6xc/edit#gid=0' },
      { label: 'Publishing', value: 'https://docs.google.com/spreadsheets/d/1nJ71YbJx1eT4FPjzcb9i_uOEDw1DSspIAZnNVG7H-JA/edit#gid=0' },
      { label: 'Advertising & media', value: 'https://docs.google.com/spreadsheets/d/1gcff_XNYUKaJbreYWaq7fb49MNlB5XaDfDv5H4BpJZc/edit#gid=0' }
    ]
  },
  {
    label: 'Government',
    value: 'government',
    subindustries: [
      { label: 'Recruitment', value: 'https://docs.google.com/spreadsheets/d/1x3BdBjPdQ_MSME7GAdEm5PK1qz_VCPNQcxQGQFj7z7A/edit#gid=0' },
      { label: 'Military', value: 'https://docs.google.com/spreadsheets/d/1PaJNdFJ3Q6-kOZm9imZE0EP1DUUC2D723vRORm4zdNY/edit#gid=0' },
      { label: 'Public service announcements', value: 'https://docs.google.com/spreadsheets/d/1p0ejB0YH67X1trk1OqQjAI4cUqi6n4FFZPh791hjGuQ/edit#gid=0' },
      { label: 'Political campaigning', value: 'https://docs.google.com/spreadsheets/d/1D1dlkprDRcHd3PZKL1iBq_CfxXvZQGMMe0U_sxxju0c/edit#gid=0' },
      { label: 'Grants & programs', value: 'https://docs.google.com/spreadsheets/d/1488rQw58EaZXE6tmvwZ1FZTLVfndsjIUdf9VRQHGcmI/edit#gid=0' },
      { label: 'Job opportunities', value: 'https://docs.google.com/spreadsheets/d/1Bar-lqCoWvkqZjN5WHP2Zp86_HAwprJWlmbTrPQAHg4/edit#gid=0' }
    ]
  },
  {
    label: 'Health & wellness',
    value: 'health-and-wellness',
    subindustries: [
      { label: 'Holistic', value: 'https://docs.google.com/spreadsheets/d/12G3zeX-D_imXRqx65TzzpZaTvfFeDfaF7t03-Sviq38/edit#gid=0' },
      { label: 'Day Spas & Massage', value: 'https://docs.google.com/spreadsheets/d/1MtaY7w0w8pe4osOfjR4t3rEGkWW_J7kUvcKKJ_vHs7A/edit#gid=0' },
      { label: 'Mental Health', value: 'https://docs.google.com/spreadsheets/d/1iUMzDhu6SdCewrl80ysr20c18Y99ukdofqzdar9Zd7A/edit#gid=0' },
      { label: 'Nutrition & supplements', value: 'https://docs.google.com/spreadsheets/d/1WHayEa5AxGebYtCH5WOvo-RmioJgal3lZcbh_spYQVw/edit#gid=0' }
    ]
  },
  {
    label: 'Healthcare',
    value: 'healthcare',
    subindustries: [
      { label: 'Dental', value: 'https://docs.google.com/spreadsheets/d/1vaVw9SyNgjTLQjoFJ6lfTjaeJM2EH3HDiCV6Wrmnjy0/edit#gid=0' },
      { label: 'General Practice', value: 'https://docs.google.com/spreadsheets/d/1BNF5_1-6gJY9XFPJDJP14qfxyIS1-WLMPchNqwYbhuE/edit#gid=0' },
      { label: 'Home healthcare services', value: 'https://docs.google.com/spreadsheets/d/1gtTjNG_I3Vqkrp3SufTMRF28nj1i8fJVcShH2yO7gXw/edit#gid=0' },
      { label: 'Orthopedics', value: 'https://docs.google.com/spreadsheets/d/1ZLfOeHEioDj7leFylrOLHbaIGVUKAg9k_ofG0pUU3gs/edit#gid=0' },
      { label: 'Elective', value: 'https://docs.google.com/spreadsheets/d/1lQaavY5V3eerdmlFSJZb_LaexpghgqFA5_Pig1nD7ZE/edit#gid=0' },
      { label: 'Emergency medical services', value: 'https://docs.google.com/spreadsheets/d/1aNVfAgmFmr1RvfsNTBQXC7obAH5ZPHURmpJfhyUIb3A/edit#gid=0' },
      { label: 'Wellness services', value: 'https://docs.google.com/spreadsheets/d/1L8IrZl6G0Puhb5s2JRWSLJwHC2tO2w-Lwy5ktY4FyZ4/edit#gid=0' },
      { label: 'Medical devices', value: 'https://docs.google.com/spreadsheets/d/1GYHKJhDkAl_V7ppjynsIy6F3vO9AGS6jjMqlPya0IDs/edit#gid=0' }
    ]
  },
  {
    label: 'Home services (e.g., cleaning, landscaping, plumbing, electrical)',
    value: 'home-services',
    subindustries: [
      { label: 'Pest control', value: 'https://docs.google.com/spreadsheets/d/1BBvctq0VAF0jH_q88I0yrZlSH36Nyy4b961Uqg1L2JY/edit#gid=0' },
      { label: 'Lawn care & landscaping', value: 'https://docs.google.com/spreadsheets/d/1G1LfzR3F_t6wVB6Kvz25b6vZRfa614XF3MoTK9JEVeA/edit#gid=0' },
      { label: 'Plumbing', value: 'https://docs.google.com/spreadsheets/d/1_C8CrkG37jVL55dxJ0-p10cE4tfSaSqop3KyjtIK884/edit#gid=0' },
      { label: 'Electrical', value: 'electrical' },
      { label: 'Windows/ doors/ siding', value: 'https://docs.google.com/spreadsheets/d/1oDnYI016xw1XSKoxpxE0UXSHSaDHWlwJBdSCNlES0CE/edit#gid=0' },
      { label: 'Cleaning', value: 'https://docs.google.com/spreadsheets/d/1ZcdsmIScBbFB4ZQVrZLNFA6aoAKP6HB96HT76w6RiZ8/edit#gid=0' },
      // { label: 'Repair & Maintenance', value: 'repair-maintenance' },
      // { label: 'Security', value: 'security' }
    ]
  },
  {
    label: 'Hospitality, Travel, & Tourism',
    value: 'hospitality-tourism',
    subindustries: [
      { label: 'Accomodations', value: 'https://docs.google.com/spreadsheets/d/1IZmw-C-RuC1YKRjMvVxgRxruZtSR_fHF1nW_cFoXkLE/edit#gid=0' },
      { label: 'Transportation', value: 'https://docs.google.com/spreadsheets/d/1aZgl05Ji593qMNnKOsGAjogr8FjB8OAfi3DUdxe5N50/edit#gid=0' },
      { label: 'Travel agencies', value: 'https://docs.google.com/spreadsheets/d/1GmA55a3Xb6Olg6QcilQjIWoPKMUyFF-ZrIRwA3nBDpQ/edit#gid=0' },
      { label: 'Attractions & activities', value: 'https://docs.google.com/spreadsheets/d/1Q7WcH0ijx_oBkzISJaelo82FEvrYX-rn3dMxb6eA-tU/edit#gid=0' },
    ]
  },
  {
    label: 'HVAC (Plumbing & Electrical)',
    value: 'hvac',
    subindustries: [
      { label: 'Installation & maintenance', value: 'https://docs.google.com/spreadsheets/d/1DMmfIs6LSk70LR5QjV4cxAH7VX-BG4oM2IsHW1wWOjg/edit#gid=0' },
      { label: 'Equipment & manufacturing', value: 'https://docs.google.com/spreadsheets/d/1_F-p_RTNnYkVWfVKVkV7FMkXz9szsCi9x1pYZNyHSok/edit#gid=0' },
      { label: 'Controls & automation', value: 'https://docs.google.com/spreadsheets/d/1neyVEUDZcG9PWj3mOGGs6_NAv5a5wyfFShuwS1ncf5g/edit#gid=0' },
      { label: 'Indoor air quality', value: 'https://docs.google.com/spreadsheets/d/17DhhZ1hXmw3MG8wWgGLi65K-0NHbbiyOEQoolvZRxNA/edit#gid=0' },
      { label: 'Commercial (B2B)', value: 'https://docs.google.com/spreadsheets/d/1IsotdfjpHJxYTPESwNCkDz02bA9B_LDzLuIau9RROaI/edit#gid=0' },
      { label: 'Efficiency & sustainability', value: 'https://docs.google.com/spreadsheets/d/1NiIQFK3KfYa9jm1zBj0DZ_cAXr_RbNe7jG77pi1Ow0s/edit#gid=0' },
      { label: 'Residential (B2C)', value: 'https://docs.google.com/spreadsheets/d/1NJ1cmC3gOtcEGL3JnIe-NWPnsAvKhM5ifQsRecm' },
    ]
  },
  {
    label: 'Industrial engineering',
    value: 'industrial-engineering',
    subindustries: [
      { label: 'Logistics & supply chain', value: 'https://docs.google.com/spreadsheets/d/1BiPLHwYsgs4rxTZvyJU0ux66iz5bDOH_mpA7Tj-4vD0/edit#gid=0' },
      { label: 'R&D', value: 'https://docs.google.com/spreadsheets/d/1SAhq6rl83upWCrTlgdfuyefHh1oAY2aW-y54iz7zKDA/edit#gid=0' },
      { label: 'Manufacturing', value: 'https://docs.google.com/spreadsheets/d/1axcT3ImUBlqNvJ54QD_SwvHtwd9rw-Q3LXuqPPVwXLk/edit#gid=0' }
    ]
  },
  {
    label: 'Information technology',
    value: 'information-technology',
    subindustries: [
      { label: 'Software development', value: 'https://docs.google.com/spreadsheets/d/1y0kCbryYpTHxo_F_482MpRMh_UevjJQ-BTzcZaf2JSs/edit#gid=0' },
      { label: 'IT services', value: 'https://docs.google.com/spreadsheets/d/1CdiEc3nwo7WW8arlhQAcN3DLZC-ytE-V7WydKcjeMsk/edit#gid=0' },
      { label: 'Cloud computing', value: 'https://docs.google.com/spreadsheets/d/18CDQnPhGkOnPdrH-PzPoacO9_lHtmK6DUZbUQYd5Om0/edit#gid=0' },
      { label: 'Cybersecurity', value: 'https://docs.google.com/spreadsheets/d/1U9fGOYAzpXaFTU0gUvYwz1muHIwB6GQTJccB5sttaHc/edit#gid=0' },
      { label: 'Data & analytics', value: 'https://docs.google.com/spreadsheets/d/1X7wds9FKhfEYwqQb996TpSg0IIS4J_pup1a0xiTcKdM/edit#gid=0' },
      { label: 'Internet of Things (IoT)', value: 'https://docs.google.com/spreadsheets/d/1iwMFIMT1Yfz94nkqHmQbFUgE3IfUkZykVacdD6Zkp8Y/edit#gid=0' }
    ]
  },
  {
    label: 'Insurance',
    value: 'insurance',
    subindustries: [
      { label: 'Life insurance', value: 'https://docs.google.com/spreadsheets/d/1LViQL4hsZFGIjBqVUbSJFhWqIAENZyMzl01UdDqq7wU/edit#gid=0' },
      { label: 'Health insurance', value: 'https://docs.google.com/spreadsheets/d/1v3vtt-Qy9DeCJefhC3neN1KNveBw59Sht4mRuv4nFLU/edit#gid=0' },
      { label: 'Property & casualty', value: 'https://docs.google.com/spreadsheets/d/1aiAY1vIFn9-hLOFFX097xSnCOJCRjYC4q-CLjx2sTno/edit#gid=0' },
      { label: 'Brokers & agents', value: 'https://docs.google.com/spreadsheets/d/16JI3S-ZgsGtZ6r7D7gbSSmN3uI1gFWJKYgEfUlxSi1c/edit#gid=0' },
    ]
  },
  {
    label: 'Interior design',
    value: 'interior-design',
    subindustries: [
      { label: 'Residential', value: 'https://docs.google.com/spreadsheets/d/11n2bMpspkAkHCwI2vFxeQ6KmRnOzULUV43tBmUQWVCU/edit#gid=0' },
      { label: 'Commercial', value: 'https://docs.google.com/spreadsheets/d/12iFFlkY1_YbCXQ1EHVE_2qHT8b6lMruwVKiumHMGgPc/edit#gid=0' },
      { label: 'Hospitality', value: 'https://docs.google.com/spreadsheets/d/1jO1WP2N0qOYmItUEBN6ZBzCEWf7xOPtrmRwztWP1RYg/edit#gid=0' },
      { label: 'Healthcare', value: 'https://docs.google.com/spreadsheets/d/1RjCMJUNiz5-LdapYyW0HK1otzfWi07gNYSU8uLcoMVI/edit#gid=0' },
      { label: 'Space & planning', value: 'https://docs.google.com/spreadsheets/d/1Xj2RjEGN5luREwhamjCHICuyjEFbY6NPh8RWzgUMmSw/edit#gid=0' },
      { label: 'Sustainable design', value: 'https://docs.google.com/spreadsheets/d/1ryRVOiKhYIWS6PFi7sclNrsqcBQ5IXDrBr7ctJ3G15U/edit#gid=0' }
    ]
  },
  {
    label: 'Legal services',
    value: 'legal-services',
    subindustries: [
      { label: 'Corporate law', value: 'https://docs.google.com/spreadsheets/d/1KbnWbtn8pR2h2vriH4b5KpQJYMY4lV2OqhYDnt1YWrw/edit#gid=0' },
      { label: 'Litigation', value: 'https://docs.google.com/spreadsheets/d/1nOI6FdNhenllMPRoOKzdGAr0cj5_a06i1JitwVqyaCI/edit#gid=0' },
      { label: 'Intellectual property', value: 'https://docs.google.com/spreadsheets/d/1CvR_AU2P0igASnM8j6weJIBcxBg1SLK8v53SCmhY7Lg/edit#gid=0' },
      { label: 'Real estate', value: 'https://docs.google.com/spreadsheets/d/1rX5VAlO0i3f7bhGaMnf3R7HdRuyd7pC1ePfTavTjjD8/edit#gid=0' },
      { label: 'Family Law', value: 'https://docs.google.com/spreadsheets/d/1ffYKWtqdVLAHnchasXcJq3ST4Z0JquIQchOgnqaWOjA/edit#gid=0' },
      { label: 'Estate Planning', value: 'https://docs.google.com/spreadsheets/d/1CiB4lRpB0FEmWrZuo7n9iSjvVFYM6L7xjdtltvB5O1Y/edit#gid=0' }
    ]
  },
  {
    label: 'Logistics and transportation',
    value: 'logistics-and-transportation',
    subindustries: [
      { label: 'Freight transportation', value: 'https://docs.google.com/spreadsheets/d/15bjoqR8-ihq2cZtQcvTOpYEucw-aGZR-US0oSqjUgMg/edit#gid=0' },
      { label: 'Warehousing', value: 'https://docs.google.com/spreadsheets/d/1uNp1E1_PMnv8STVoTUUCNIQm715ZXtUOOL9Tg6pC-Mo/edit#gid=0' }
    ]
  },
  {
    label: 'Music',
    value: 'music',
    subindustries: [
      { label: 'Recording', value: 'https://docs.google.com/spreadsheets/d/1OskGj0LFr9MwCJs13U3eeQMh5bjwa6HsePK36uok1Qw/edit#gid=0' },
      { label: 'Live events', value: 'https://docs.google.com/spreadsheets/d/1M457kcp3aNTaSzwsd3igQ0GMSY1BVfC6j5hZpD1NE6g/edit#gid=0' },
      { label: 'Musician or band', value: 'https://docs.google.com/spreadsheets/d/1e9TjhAl4FtBLMtIcNbenbgFlMkiLj8R0OfB9s4yDf-0/edit#gid=0' },
      { label: 'Distribution', value: 'https://docs.google.com/spreadsheets/d/17lceY9YtwQV6MjvSmX4zLEV2VMTKJ2VGzMb3nqEnHR0/edit#gid=0' },
      { label: 'Artist management', value: 'https://docs.google.com/spreadsheets/d/15_SHRh_WaCoOiAD7ysTOg1ZLw5fx7yTrhjaIlujl6dw/edit#gid=0' },
      { label: 'Education', value: 'https://docs.google.com/spreadsheets/d/1fmyMl-l1nl7LEQUJCZcZVqhf5b-XEvdsvl0OkpH5u_0/edit#gid=0' },
    ]
  },
  {
    label: 'Nonprofit and philanthropy',
    value: 'nonprofit-and-philanthropy',
    subindustries: [
      { label: 'Charitable organizations', value: 'https://docs.google.com/spreadsheets/d/1sBGgUyElGCv5umT4aX4Yt0VIYmuNoxqiHdFc8SScly4/edit#gid=0' },
      { label: 'Education', value: 'https://docs.google.com/spreadsheets/d/1RN8L82R18_ASXUEmv3s9Y1pb3FNxjevwNwfFjk8_wVo/edit#gid=0' },
      { label: 'Environmental', value: 'https://docs.google.com/spreadsheets/d/1ByqJl_w70PAqqpVU76duyAAU8hurVD7pd3JdUcit-g8/edit#gid=0' },
      { label: 'Aid organizations', value: 'https://docs.google.com/spreadsheets/d/1IAb9f6BisInJjNEpFxqbezrDUVJxVJ5888b1smVlu9k/edit#gid=0' }
    ]
  },
  {
    label: 'Oil and gas',
    value: 'oil-and-gas',
    subindustries: [
      { label: 'Exploration & production', value: 'https://docs.google.com/spreadsheets/d/1JOoYk5DffJ6qtUOZNytmOwkN4RN4H0SD7Cmwl6hX5Cg/edit#gid=0' },
      { label: 'Refining', value: 'https://docs.google.com/spreadsheets/d/1lBXvSdwcRrjO_8qBfj0j6gNIfeV03sebN_y5WNYmIDk/edit#gid=0' },
      { label: 'Oilfield services', value: 'https://docs.google.com/spreadsheets/d/1vAi1SEQEvmBYb2hN-7lgMzffSJkf-vLyrcFCYAig1xQ/edit#gid=0' },
      { label: 'Pipeline & transportation', value: 'https://docs.google.com/spreadsheets/d/1MX5NJ7mF4zYmoQFlbVBNADFtBxq3giqoz_idjU3vSOY/edit#gid=0' },
      { label: 'Natural gas', value: 'https://docs.google.com/spreadsheets/d/1_d1P6ELrdtlLmBvZXebKssVXxt5H54szsUzngHDeCso/edit#gid=0' },
      { label: 'Petrochemical', value: 'https://docs.google.com/spreadsheets/d/1jywDgjUR1TB8tsZ88dGsx2yGtM7jmIrmuLdxqnQ76Bg/edit#gid=0' },
      { label: 'Alternative energy', value: 'https://docs.google.com/spreadsheets/d/1wKJDuP4zS6xMonxTKJFmLNwr3la6HfTQUUx_md599ZU/edit#gid=0' }
    ]
  },
  {
    label: 'Public transportation',
    value: 'public-transportation',
    subindustries: [
      { label: 'Charter busses', value: 'https://docs.google.com/spreadsheets/d/15Ii0C5eRYunx1sf-txpuISALzewU8l7prSuqlN5cRE8/edit#gid=0' },
      { label: 'Bike & scooter rental', value: 'https://docs.google.com/spreadsheets/d/1ghL5lOtPdgRJjIoEgD9J6QlFp3BI82Uk_hccqc-t86g/edit#gid=0' },
      { label: 'Shuttle & limousine services', value: 'https://docs.google.com/spreadsheets/d/1DlAfTx-p6mUEZADB5Kj79Im6hWS-kU5TT_ugOhlQwQE/edit#gid=0' },
      { label: 'Paratransit', value: 'https://docs.google.com/spreadsheets/d/12AEy7iABoIHUGI3_PmSvu5DNjk3z5ENeEAprDgdTNTs/edit#gid=0' }]
  },
  {
    label: 'Real estate',
    value: 'real-estate',
    subindustries: [
      { label: 'Residential', value: 'https://docs.google.com/spreadsheets/d/1GDH0ew1dUAFT9Mog17rpuZOe0EbowAjhT2r2-yn24Wc/edit#gid=0' },
      { label: 'Commercial', value: 'https://docs.google.com/spreadsheets/d/1mHo_7eBmxBDyixLx4fveKV6hhSNhEaI3JSiD2pkY8o4/edit#gid=0' },
      { label: 'Industrial', value: 'https://docs.google.com/spreadsheets/d/1mSclPqk86_sqPAialLWSz9HlffRpfjLG8-1f0MNyU_k/edit#gid=0' },
      { label: 'Property management', value: 'https://docs.google.com/spreadsheets/d/1exeE4mRFHgGqM6vEqImL5o3xkGQkQ6NWQg-H-yDilw0/edit#gid=0' },
      { label: 'Financing', value: 'https://docs.google.com/spreadsheets/d/1Aa68urN7pR0so4uZdhsUYUcAgeGFbLU3bIr4QpXfoRA/edit#gid=0' },
      { label: 'Brokerage', value: 'https://docs.google.com/spreadsheets/d/1Jxr2fM_Q3fE-RywhguQ9podnZerJxebeOq4xN-fRi-M/edit#gid=0' }
    ]
  },
  {
    label: 'Retail',
    value: 'retail',
    subindustries: [
      { label: 'Brick-and-mortar', value: 'https://docs.google.com/spreadsheets/d/1yk8bYJ0P4RIHfDktlK8-QBYkckn-SFi6uSAnUnXb6LA/edit#gid=0' },
      { label: 'Wholesale', value: 'https://docs.google.com/spreadsheets/d/13RD1MTr0sXkzXmbTH7teMEv3TsqFI_M3QggV6ZpiGSE/edit#gid=0' },
      { label: 'Franchising', value: 'https://docs.google.com/spreadsheets/d/1txAnF9TAPq5D_0mLvfW_pRMOrNNAqxMZyOexPSxm_DU/edit#gid=0' }
    ]
  },
  {
    label: 'Security',
    value: 'security',
    subindustries: [
      { label: 'Physical security', value: 'https://docs.google.com/spreadsheets/d/1aMLhhJBjtVYhsn-O3wJhdZ9Vq8ZQB1LSJQRHUgzTCoM/edit#gid=0' },
      { label: 'Emergency management', value: 'https://docs.google.com/spreadsheets/d/11E8x-VXyw4oC6i7Mrq_U3WgZz6UuFmWG-B2naU9aa7U/edit#gid=0' },
      { label: 'Investigative services', value: 'https://docs.google.com/spreadsheets/d/18o_1ScyQ0b19Gusp6aCu-_0X2QVPKxLsNimg6dQVmWs/edit#gid=0' },
      { label: 'Cybersecurity', value: 'https://docs.google.com/spreadsheets/d/1F0vCizqrok7fSedd_QIXp5NtHxqfjfY5AGBm-okhrZk/edit#gid=0' },
      { label: 'Fire protection', value: 'https://docs.google.com/spreadsheets/d/1H18-vs6_BDMKpwuPBocwz89v_I-22rwXedUSLsBrEb0/edit#gid=0' }
    ]
  },
  {
    label: 'Sports',
    value: 'sports',
    subindustries: [
      { label: 'Team & leagues', value: 'https://docs.google.com/spreadsheets/d/1DhK9CQOKawRht_GaYGIDr03K5YqvfZrvlpScT6t-qmY/edit#gid=0' },
      { label: 'Equipment', value: 'https://docs.google.com/spreadsheets/d/10Wj1JHLlf1EmQb6bXzQfqsQVRwu9QoDrtb6jLtViMds/edit#gid=0' },
      { label: 'Facilities & venues', value: 'https://docs.google.com/spreadsheets/d/1McI4X47RoJPZGLuIr5dm1YOL5KqfQ4WOr8YhILjz3BA/edit#gid=0' },
      { label: 'Betting & gaming', value: 'https://docs.google.com/spreadsheets/d/10kebEZ-_3Yn45oqysFhiCs9KHxmxUHdvQQHgF_yTVqg/edit#gid=0' },
    ]
  },
  {
    label: 'Telecommunications',
    value: 'telecommunications',
    subindustries: [
      { label: 'Network Infrastructure', value: 'https://docs.google.com/spreadsheets/d/1z8U99x2Yda1cGEpwfJ4tiDBUpVJdyd9y4zXVc22jlKQ/edit#gid=0' },
      { label: 'Mobile devices & services', value: 'https://docs.google.com/spreadsheets/d/1BHHjDhPvK6JN4R1fpONJbaezCL49RH2RL3nQ2_DKuEk/edit#gid=0' },
      { label: 'Data centers', value: 'https://docs.google.com/spreadsheets/d/1wGDYuPQONDlzRmF4LQh7dqRBwH827OyunPtwaZQMXgs/edit#gid=0' }
    ]
  },
  {
    label: 'Transportation',
    value: 'transportation',
    subindustries: [
      { label: 'Airline', value: 'https://docs.google.com/spreadsheets/d/1kHR8yLNNamXC1wyTo3qJWAxkZWs9-KYu3N_bnql47sY/edit#gid=0' },
      { label: 'Maritime', value: 'https://docs.google.com/spreadsheets/d/1cSpTyBST9iauAePM8Z3StB4Ardn7VZYy9sPej6jtSsI/edit#gid=0' },
      { label: 'Rail', value: 'https://docs.google.com/spreadsheets/d/1WOBJpoMS91i3b7dAxYxUp-2-zi_XrVZaEKa886PlOnE/edit#gid=0' },
      { label: 'Road', value: 'https://docs.google.com/spreadsheets/d/1Rdxhoxxt9ZMJllGv30P_PHqR-h7KxRgSFOvU0g2dcPo/edit#gid=0' }
    ]
  },
  {
    label: 'Veterinary medicine',
    value: 'veterinary-medicine',
    subindustries: [
      { label: 'General Veterinary medicine', value: 'https://docs.google.com/spreadsheets/d/1otKvkQjGSZn4W1HanbCtiWk2wd8-M6M3kqSxox5bs0c/edit#gid=0' },
      { label: 'Specialt Veterinaryy services', value: 'https://docs.google.com/spreadsheets/d/1rmsFDSb7g68ZcZoK_vz6uVdpXgAuB4ZyL4Lx1PrMH5c/edit#gid=0' }
    ]
  }
  ]
export default options