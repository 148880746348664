import React, { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Import uuid for unique IDs
import left from './left.png';
import right from './right.png';
import playIcon from './playIcon.png';
import warning from '../Images/warning.svg';
import AdVoice from '../AdVoice/AdVoice.js';
import { FaRegTrashAlt } from "react-icons/fa";
import LottieAnimation from '../Lottie/Lottie.js';
import Campaign from './Campaign.js'; // Assuming this renders the campaign form
import './create.css';
import logo from '../AdVoice/aeet.png';
import CheckoutBanner from './CheckoutBanner.js';
import checkmarkAnim from "../Lottie/59945-success-confetti.json";

function CampaignBuilder() {
    const [errorMsg, setErrorMsg] = useState(null);
    const [activeToggle, setActiveToggle] = useState("video");
    const [campaigns, setCampaigns] = useState([{ id: uuidv4(), budget: 0, isReady: false }]); // Minimal campaign data
    const [totalBudget, setTotalBudget] = useState(0);

    // Function to add a new campaign with a unique uuid
    const addNewCampaign = () => {
        const newCampaign = {
            id: uuidv4(),
            budget: 0,
            isReady: false,
        };
        setCampaigns([...campaigns, newCampaign]);
    };

    useEffect(() => {
        console.log('Campaigns updated:', campaigns);
    }, [campaigns]);

    // Function to remove a campaign by ID
    const removeCampaign = useCallback((id) => {
        const updatedCampaigns = campaigns.filter(campaign => campaign.id !== id);
        setCampaigns(updatedCampaigns);
        calculateTotal(updatedCampaigns);
    }, [campaigns]);

    // Callback function to handle budget updates from Campaign component
    const handleBudgetChange = useCallback((id, newBudget) => {
        const updatedCampaigns = campaigns.map(campaign =>
            campaign.id === id ? { ...campaign, budget: newBudget } : campaign
        );
        setCampaigns(updatedCampaigns);
        calculateTotal(updatedCampaigns);
    }, [campaigns]);

    // Function to calculate the total budget
    const calculateTotal = useCallback((campaignsList) => {
        const total = campaignsList.reduce((sum, campaign) => sum + campaign.budget, 0);
        setTotalBudget(total);
    }, []);

    // Handle updates for detailed campaign data, storing it directly in campaigns
    const handleCampaignUpdates = useCallback((id, newData) => {
        const updatedCampaigns = campaigns.map(campaign =>
            campaign.id === id ? { ...campaign, ...newData } : campaign
        );
        setCampaigns(updatedCampaigns);
    }, [campaigns]);

    return (
        <div id="master-grid" style={{ marginTop: '26px' }}>
            <img src={left} alt="left" style={{ width: '100%', top: '40px', left: '0px' }} />

            <div id="create-container-new">
                {activeToggle === "video" && (
                    <AdVoice
                        assistant_id="asst_x2eFu3RvIsrG10Nn1CfRug7B"
                        name="Generate Voiceovers"
                        logo={logo}
                        headerColor="#00a375"
                    />
                )}

                <div style={{ margin: 'auto' }}>
                    <h1 style={{ textAlign: 'center', marginBottom: '0px', fontSize: '30px' }}>
                        Advertise Across 40+ Premium Networks
                    </h1>
                    <h3
                        style={{
                            textAlign: 'center',
                            marginTop: '0px',
                            fontWeight: 500,
                            marginBottom: '14px',
                        }}
                    >
                        Reach your customers, <strong style={{ color: 'var(--brand-color)' }}>whenever</strong> and{' '}
                        <strong style={{ color: 'var(--brand-color)' }}>wherever</strong> they are streaming
                    </h3>
                    <div
                        style={{
                            display: 'flex',
                            margin: 'auto',
                            gap: '8px',
                            width: 'fit-content',
                            borderRadius: '30px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginBottom: '18px',
                            border: '2px solid #004432',
                        }}
                        onClick={() => {
                            window.open('https://share.vidyard.com/watch/Jx6bXMfvMsKLU9GayRp8gP', '_blank');
                        }}
                    >
                        <img style={{ width: '20px' }} src={playIcon} alt="playIcon"></img>
                        <div style={{ color: '#004432', fontFamily: 'DM Sans', fontWeight: 600 }}>How it works</div>
                    </div>
                </div>

                {/* Render the campaigns dynamically */}
                {campaigns.map((campaign, key) => (
                    <div
                        key={campaign.id}
                        id={campaign.id}
                        style={{
                            backgroundColor: 'white',
                            position: 'relative',
                            width: '530px',
                            margin: 'auto',
                            padding: '16px',
                            borderRadius: '20px',
                            marginBottom: '20px',
                            border: '1px solid lightgrey',
                        }}
                    >
                        <h4 style={{ margin: 0 }}>Campaign #{campaign.id}</h4>

                        <div
                            style={{
                                marginTop: '0px',
                                display: 'flex',
                                height: '60px',
                                position: 'absolute',
                                right: '18px',
                                top: '0px',
                            }}
                        >
                            <button
                                onClick={() => removeCampaign(campaign.id)}
                                style={{
                                    backgroundColor: 'transparent',
                                    color: 'black',
                                    border: 'none',
                                    padding: '10px',
                                    cursor: 'pointer',
                                    fontSize: '16px', // Adjust the size of the icon
                                }}
                            >
                                <FaRegTrashAlt />
                            </button>
                            {campaign.isReady ? (
                                <LottieAnimation
                                    animationData={checkmarkAnim}
                                    height={'60px'}
                                    width={'60px'}
                                />
                            ) : null}
                        </div>
                        <Campaign
                            key={key}
                            camp_id={campaign.id}
                            onBudgetChange={(newBudget) => handleBudgetChange(campaign.id, newBudget)}
                            handleCampaignUpdates={(newData) => handleCampaignUpdates(campaign.id, newData)}
                            campaignData={campaign} // Pass current campaign data
                        />
                    </div>
                ))}

                {/* Button to add a new campaign */}
                <button
                    id="newCampaignBtn"
                    onClick={addNewCampaign}
                    style={{
                        display: 'block',
                        margin: 'auto',
                        marginTop: '20px',
                        fontFamily: 'DM Sans',
                        fontSize: '16px',
                        fontWeight: 600,
                        borderRadius: '20px',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        backgroundColor: '#004432',
                        color: 'white',
                    }}
                >
                    New Campaign
                </button>

                {errorMsg && (
                    <div className="error-banner">
                        <div style={{ display: 'flex' }}>
                            <img src={warning} style={{ width: '22px', marginRight: '10px' }} alt="warning" />
                            <div>{errorMsg}</div>
                        </div>
                    </div>
                )}
            </div>

            <img src={right} alt="right" style={{ width: '100%', top: '40px', right: '0px' }} />

            {/* Checkout Banner at the bottom */}
            <CheckoutBanner campaigns={campaigns} totalBudget={totalBudget} />
        </div>
    );
}

export default CampaignBuilder;
