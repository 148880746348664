import React, { useState, useEffect } from 'react';
import './TypingIndicator.css'; // Import your CSS file for styling

const TypingIndicator = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="bouncing-typing-indicator">
    <div className="dot" id="dot1"></div>
    <div className="dot" id="dot2"></div>
    <div className="dot" id="dot3"></div>
  </div>

  );
};

export default TypingIndicator;
